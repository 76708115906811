import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { firebase } from "./firebase";
import "firebase/compat/messaging";
import "./App.less";
import jwt_decode from "jwt-decode";
/** Layouts **/
import LoginLayoutRoute from "./layouts/loginLayout";
import DashboardLayoutRoute from "./layouts/dashboardLayoutRoute";
/** Components **/
import Dashboard from "./components/dashboard/dashboard";
import Transactions from "./components/transactions/transactions";
//import Deposits from "./components/deposits/deposits";
import Signin from "./components/signin/signin";
// import Payments from "./components/payments/payments";
// import Orders from "./components/orders/orders";
import Fiat from "./components/fiat/fiat";
import Crypto from "./components/crypto/crypto";
import Users from "./components/users/users";
import Actions from "./components/users/actions/actions";
import EditUser from "./components/users/actions/editUser/editUser";
import FreezeWithdrawals from "./components/freezeWithdrawals/freezeWithdrawals";
import Exchange from "./components/exchange/exchange";
import Team from "./components/team/team";
import Cards from "./components/cards/cards";
import DepositsWithdrawals from "./components/depositsWithdrawals/depositsWithdrawals";
import Profile from "./components/profile/profile";
import BusinessBridge from "./components/businessBridge/businessBridge";
import Commissions from "./components/commissions/commissions";
import AmlScreening from "./components/amlScreening/amlScreening";
import Trade from "./components/trade/trade";
import PendingSettlements from "./components/pendingSettlements/pendingSettlements";
/** Redux **/

import { refreshToken, currentToken } from "./redux/actions/tokenActions";
import { connect } from "react-redux";
import { resetStore } from "./redux/actions/authActions";
import servicesUser from "./services/user";
window.Buffer = window.Buffer || require("buffer").Buffer;
let messaging = null;
if (firebase.firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

class App extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      viewModal: false,
      timeSession: 480000,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getTokenMessaging();
    try {
      var decoded = jwt_decode(this.props.token);
      if (Date.now() >= decoded.exp * 1000) {
        this.props.resetStore();
        return <Redirect to="/signin" />;
      }
    } catch (error) {
      if (error.message !== undefined) {
        if (error.message === "Invalid token specified") {
          this.props.resetStore();
          return <Redirect to="/signin" />;
        }
      }
    }

    setInterval(() => {
      if (window.localStorage.getItem("success")) {
        this.refreshToken();
      }
    }, 800000);
  }

  async refreshToken() {
    if (this.props.dataUser !== undefined) {
      if (this.props.dataUser.authResult?.success) {
        try {
          const response = await servicesUser.refreshToken(
            this.props.clientId,
            this.props.token
          );
          await this.props.refreshToken(response);

          if (response.headers !== undefined) {
            let newAccessToken = response.headers["x-new-access-token"];
            await this.props.currentToken(newAccessToken);
          }
        } catch (error) {
          console.log(error);
          window.localStorage.setItem("success", false);
          if (error.response.status === 401 || error.response.status === 422) {
            window.localStorage.setItem("success", false);
            return <Signin />;
          }
        }
      }
    }
  }

  getTokenMessaging() {
    if (messaging !== null) {
      try {
        messaging
          .getToken({
            vapidKey:
              "BBfrd1YpRkxtiTR1sBAhilTDfpQCn6yIiFpNIi2Zx1nquhtppyMjEMYaZWk12KzL-X2-evNv9jdOKAqtSSDq788",
          })
          .then((currentToken) => {
            if (currentToken) {
              window.localStorage.setItem("token", currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        // console.log(error);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          ></Route>
          <LoginLayoutRoute exact path="/signin" component={Signin} />
          <Route
            exact
            path="/dashboard"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/transactions"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/transactions"
                  component={Transactions}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          {/* <Route
            exact
            path="/payments"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/payments"
                  component={Payments}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/orders"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/orders" component={Orders} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          /> */}
          <Route
            exact
            path="/commissions"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/commissions"
                  component={Commissions}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/fiat"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/fiat" component={Fiat} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/crypto"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/crypto" component={Crypto} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/users"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/users" component={Users} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/users/actions"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/users/actions"
                  component={Actions}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/users/actions/editUser"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/users/actions/editUser"
                  component={EditUser}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/freeze"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/freeze"
                  component={FreezeWithdrawals}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/exchange"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/exchange"
                  component={Exchange}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/team"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/team" component={Team} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/cards"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute exact path="/cards" component={Cards} />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/depositsWithdrawals"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/depositsWithdrawals"
                  component={DepositsWithdrawals}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/businessBridge"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/businessBridge"
                  component={BusinessBridge}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/profile"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/profile"
                  component={Profile}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/screening"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/screening"
                  component={AmlScreening}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/trade"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/trade"
                  component={Trade}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
          <Route
            exact
            path="/pending-settlements"
            render={() => {
              return window.localStorage.getItem("success") ? (
                <DashboardLayoutRoute
                  exact
                  path="/pending-settlements"
                  component={PendingSettlements}
                />
              ) : (
                <Redirect to="/signin" />
              );
            }}
          />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    token: state.tokenAccess.current,
    refresh: state.tokenAccess.tokenResponse,
    clientId: state.tokenAccess.clientId,
  };
};
const mapDispatchToProps = {
  refreshToken,
  currentToken,
  resetStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

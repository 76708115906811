import React from 'react';
import { RightOutlined } from "@ant-design/icons";
import ExpandedRow from './ExpandedRow';

export const useExpandableConfig = ({ expandedRowKeys, setExpandedRowKeys, fields }) => {
  return {
    expandedRowRender: (record) => <ExpandedRow record={record} fields={fields} />,
    expandedRowKeys,
    expandIcon: ({ expanded, onExpand, record }) => (
      <RightOutlined
        rotate={expanded ? 90 : 0}
        onClick={(e) => onExpand(record, e)}
        style={{
          fontSize: "12px",
          color: "#fff",
          transition: "0.3s",
          cursor: "pointer",
        }}
      />
    ),
    onExpand: (expanded, record) => {
      setExpandedRowKeys(expanded ? [record._id] : []);
    },
  };
};

export const useExpandableRowConfig = (expandedRowKeys, setExpandedRowKeys) => {
  return (record) => ({
    onClick: () => {
      const isExpanded = expandedRowKeys.includes(record._id);
      setExpandedRowKeys(isExpanded ? [] : [record._id]);
    },
  });
}; 
export const TRANSACTION = "TRANSACTION";
export const DATA = "DATA";
export const RULES_FTM="RULES_FTM"

export function getTransactions(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTION,
      payload: response,
    });
  };
}
export function getRules(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: RULES_FTM,
      payload: response,
    });
  };
}
export function clearTransactions() {
  return (dispatch, getState) => {
    dispatch({
      type: TRANSACTION,
      payload: {},
    });
  };
}
export function clearDataSource() {
  return (dispatch, getState) => {
    dispatch({
      type: DATA,
      payload: {},
    });
  };
}
import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const aml = {
  async getTransactionAml(idToken, accessToken, filter) {
    let baseUrl = BASE_URL + config.getTransactionsAml;
    const resp = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },

      params: {
        transaction_hash: filter?.transaction_hash || null,
        transaction_id: filter?.transaction_id || null,
        entity: filter?.entity || null,
        platform: filter?.platform || null,
        currency_code: filter?.currency_code || null,
        status: filter?.status || null,
        min_date: filter?.min_date || null,
        max_date: filter?.max_date || null,
        user_email: filter?.user_email || null,
        type: filter?.type || null,
        last_id: filter?.last_id || null,
        user_id: filter?.user_id || null,
      },
    });
    return resp;
  },
  async confirmDeclineTransaction(idToken, accessToken, body, idTransaction) {
    const resp = await axios.post(
      BASE_URL + config.changeStatusTransactionMonitoring + idTransaction,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async confirmAprovedTransaction(idToken, accessToken, body, idTransaction) {
    // console.log("id", idTransaction);
    const resp = await axios.post(
      BASE_URL + config.changeStatusTransactionMonitoring + idTransaction,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async getCurrencies(clientId, accessToken) {
    const resp = await axios.get(BASE_URL + config.supportedCurrencies, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": clientId,
      },
    });
    return resp;
  },
};
export default aml;

import React from 'react';
import { Space, Typography } from 'antd';

const { Text } = Typography;

const EmptyTableMessage = () => (
  <Space direction="vertical">
    <br />
    <Text
      style={{
        color: "#fff",
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      Hmm, we didn't find any records
    </Text>
    <Text
      style={{
        color: "#fff",
        fontSize: 16,
        fontWeight: "normal",
      }}
    >
      Please check your filters and try again
    </Text>
    <br />
  </Space>
);

export default EmptyTableMessage; 
// Dependencies
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

// Services
import pendingSettlements from "../../../services/pendingSettlements";

export const useTableData = () => {
  // State for table data and pagination
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [lastId, setLastId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  // Redux state selectors for authentication
  const { token, clientId } = useSelector((state) => ({
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  }));

  // Handler for fetching table data
  const fetchData = async (params = {}, isNewSearch = false) => {
    try {
      setLoading(true);
      // Clean params by removing null, empty and 'ALL' values
      const cleanParams = Object.fromEntries(
        Object.entries({ ...params }).filter(
          ([_, value]) => value != null && value !== "" && value !== "ALL"
        )
      );

      const response = await pendingSettlements.getTransactions(
        clientId,
        token,
        {
          ...cleanParams,
          last_id: isNewSearch ? null : lastId,
          platform: "BUSINESS",
        }
      );

      if (!response.data?.data?.transactions) {
        throw new Error("Invalid response format");
      }

      const { transactions, current_last_id, max_elements_per_page } =
        response.data.data;

      // Update data based on whether it's a new search or pagination
      setData(isNewSearch ? transactions : [...data, ...transactions]);
      setLastId(current_last_id);

      // TODO: If backend provides total records, calculate total pages here
      // Update pagination state
      setPagination((prev) => ({
        ...prev,
        pageSize: max_elements_per_page || prev.pageSize,
        total:
          (isNewSearch ? transactions : [...data, ...transactions]).length +
          (current_last_id ? 1 : 0),
      }));

      return response;
    } catch (error) {
      // Reset data and show error message on failure
      setData([]);
      setPagination((prev) => ({ ...prev, total: 0 }));

      if (error.response?.data) {
        message.error(error.response.data.message);
      } else {
        message.error("Error fetching data");
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Handler for search operations
  const handleSearch = async (searchFilters = {}) => {
    setFilters(searchFilters);
    setLastId(null);
    setPagination((prev) => ({ ...prev, current: 1 }));
    return await fetchData(searchFilters, true);
  };

  // Handler for table pagination changes
  const handleTableChange = async (newPagination) => {
    setPagination(newPagination);
    const currentSize = pagination.current * pagination.pageSize + 1;

    if (
      pagination.current < newPagination.current &&
      currentSize === pagination.total
    ) {
      await fetchData(filters);
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading,
    pagination,
    handleSearch,
    handleTableChange,
    refreshData: () => fetchData(filters, true),
  };
};

import { useEffect } from 'react';

export const useResizeObserverErrorHandler = () => {
  useEffect(() => {
    const errorHandler = (e) => {
      const errorMessage = e?.message || e?.reason?.message || '';
      
      if (typeof errorMessage !== 'string') {
        return;
      }

      const isResizeObserverError = 
        errorMessage.includes("ResizeObserver loop completed with undelivered notifications") ||
        errorMessage.includes("ResizeObserver loop limit exceeded");

      if (isResizeObserverError) {
        e.stopPropagation();
        
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
        
        e.preventDefault();
      }
    };

    window.addEventListener('error', errorHandler, true);
    window.addEventListener('unhandledrejection', errorHandler, true);

    return () => {
      window.removeEventListener('error', errorHandler, true);
      window.removeEventListener('unhandledrejection', errorHandler, true);
    };
  }, []);
}; 
import React, { useEffect, useState } from "react";
import "./bridge.less";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Tag,
  Table,
  message,
  Skeleton,
  Space,
  Image,
  Divider,
} from "antd";
import {
  usersDamex,
  userAccounts,
  clearUsersDamex,
  clearAccounts,
} from "../../redux/actions/otcActions";
import { InfoCircleOutlined } from "@ant-design/icons";
import ModalCreateTransactions from "../createTransactionOTC/createTransactionOTC";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
const { Text } = Typography;
const { Option } = Select;
const BusinessBridge = () => {
  const [usersList, setUsersList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [modalTransactionOTC, setModalTransactionOTC] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [lastIdUsers, setLastIdUsers] = useState(null);
  const [showButtonLoad, setShowButtonLoad] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [userInfo, setUserInfo] = useState("");

  const { accountResp, token, usersListResp } = useSelector((state) => ({
    accountResp: state.otc.accounts,
    token: state.tokenAccess,
    usersListResp: state.otc.users,
  }));
  useEffect(() => {
    getUsers();
    return () => {
      setLoading({});
      dispatch(clearUsersDamex());
      dispatch(clearAccounts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let keys = Object.keys(usersListResp);
    if (keys.length > 0) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersListResp]);
  useEffect(() => {
    let keys = Object.keys(accountResp);
    if (keys.length > 0) {
      if (accountResp.status === 200) {
        setAccountList(accountResp.data.data.accounts);
        setLoadingSearch(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountResp]);
  async function formatData() {
    const response = usersListResp;
    let lastID = null;
    if (lastIdUsers === null) {
      lastID = undefined;
    } else {
      lastID = lastIdUsers;
    }
    if (response.status === 200) {
      let data = response.data.data;
      let users = response.data.data.users;
      if (lastID !== undefined && lastID === data.previous_id) {
        users = [...usersList, ...users];
      }
      let orderedList = users.sort(function (a, b) {
        let fullNameA = "";
        let fullNameB = "";

        fullNameA = a.name;

        fullNameB = b.name;

        return fullNameA.localeCompare(fullNameB);
      });
      if (data.current_page_size < data.max_elements_per_page) {
        setShowButtonLoad(false);
      } else {
        setShowButtonLoad(true);
      }
      setLastIdUsers(
        data.current_last_id !== null ? data.current_last_id : null
      );
      setUsersList(orderedList);
      setLoading(false);
    }
  }
  async function getUsers() {
    try {
      setLoading(true);
      let lastID = null;
      if (lastIdUsers === null) {
        lastID = undefined;
      } else {
        lastID = lastIdUsers;
      }
      dispatch(usersDamex(token.clientId, token.current, lastID));
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.error_details.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
        message.error("Invalid token");
      } else if (error.response.status === 422) {
        message.error("Invalid token");
      } else if (error.response.status === 401) {
        message.error("Expired token");
      } else if (error.response.status === 403 && error.response.status === 500)
        message.error(error.response.data.message);
    }
  }
  function validationError(messageError, otherMessage) {
    if (messageError === "INVALID_EMAIL_FORMAT") {
      message.error("'Email' is invalid", 10);
    } else if (messageError === "INVALID_LAST_USER") {
      message.error("Last_id is invalid", 10);
    } else {
      message.error(otherMessage, 10);
    }
  }

  async function getAccounts() {
    try {
      let userId = currentUser.split("_")[0];
      setLoadingSearch(true);
      dispatch(userAccounts(token.clientId, token.current, userId));
      let findUser = usersList.find((element) => {
        return element._id === currentUser.split("_")[0];
      });
      if (findUser !== undefined) {
        setUserInfo(findUser);
      }
    } catch (error) {
      setLoadingSearch(false);
      if (error.response.status === 400) {
        if (error.response.data.error_details.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
        message.error("Invalid token");
      } else if (error.response.status === 422) {
        message.error("Invalid token");
      } else if (error.response.status === 401) {
        message.error("Expired token");
      } else if (error.response.status === 403 && error.response.status === 500)
        message.error(error.response.data.message);
    }
  }

  function openModalSendToOtc(rowData) {
    setSelectedAccount(rowData);
    setModalTransactionOTC(true);
  }
  function handleUser(value) {
    setAccountList([]);
    setUserInfo("");
    setCurrentUser(value);
  }
  function closeModalSendToOtc() {
    setModalTransactionOTC(false);
  }
  function getInfoCurrency(currency) {
    let data = asset.assets;
    let findCurrency = data.find((element) => element.value === currency);
    return findCurrency;
  }
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
      render: (currency) => (
        <>
          <Space align="center">
            <Image
              style={{ alignItems: "center", display: "inline-flex" }}
              preview={false}
              width={25}
              src={getInfoCurrency(currency).icon}
            />
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
              }}
            >
              {getInfoCurrency(currency).name}
            </Text>
          </Space>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Type
        </Text>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Status
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          {status === "ACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#389E0D",
                background: "#F6FFED",
                borderColor: "#B7EB8F",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}
          {status === "INACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#08979C",
                background: "#E6FFFB",
                borderColor: "#87E8DE",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Available balance
        </Text>
      ),
      dataIndex: "available_balance",
      key: "available_balance",
      render: (record, originNode) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
            }}
          >
            <NumberFormat
              thousandSeparator={true}
              prefix={getInfoCurrency(originNode.currency).symbol + " "}
              displayType="text"
              decimalScale={originNode.type === "FIAT" ? 2 : 8}
              value={originNode.available_balance}
            />
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Locked balance
        </Text>
      ),
      dataIndex: "locked_balance",
      key: "locked_balance",
      render: (record, originNode) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
            }}
          >
            <NumberFormat
              thousandSeparator={true}
              prefix={getInfoCurrency(originNode.currency).symbol + " "}
              displayType="text"
              decimalScale={originNode.type === "FIAT" ? 2 : 8}
              value={originNode.locked_balance}
            />
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Actions
        </Text>
      ),
      key: "action",
      render: (record, originNode) => (
        <Space size="middle">
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={() => openModalSendToOtc(originNode)}
          >
            Send to OTC
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Card
        className="card-transactions"
        bodyStyle={{ padding: "10px 0px 10px 0px" }}
      >
        <Row
          gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
          style={{ padding: "5px 10px" }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 7, offset: 0 }}
            xl={{ span: 7, offset: 0 }}
            xxl={{ span: 7, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) => {
                let value = option.value.split("_")[1];
                return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              style={{ width: "100%" }}
              size="large"
              placeholder="Users"
              loading={loading}
              disabled={loading}
              onChange={(value) => handleUser(value)}
              value={currentUser}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {showButtonLoad && (
                    <div style={{ background: "#00001E" }}>
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }} align="center">
                        <Button type="link" onClick={() => getUsers()}>
                          Load more
                        </Button>
                      </Space>
                    </div>
                  )}
                </>
              )}
            >
              {usersList.map((item, i) => {
                return (
                  <Option value={item._id + "_" + item.name} key={item._id}>
                    {item.name + " - " + item.email}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            xxl={{ span: 3, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              disabled={loading || currentUser === ""}
              onClick={() => getAccounts()}
              loading={loadingSearch}
            >
              Search
            </Button>
          </Col>
          {(userInfo.type === "DAMPAY_INDIVIDUAL_LITE" ||
            userInfo.type === "DAMPAY_BUSINESS_LITE") &&
            !loadingSearch && (
              <>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 1, offset: 0 }}
                  xl={{ span: 1, offset: 0 }}
                  xxl={{ span: 1, offset: 0 }}
                ></Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 1, offset: 0 }}
                  xl={{ span: 1, offset: 0 }}
                  xxl={{ span: 1, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <InfoCircleOutlined
                    style={{
                      color: "#00ff6a",
                      fontSize: 30,
                    }}
                  />
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  xxl={{ span: 12, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Text
                    style={{
                      color: "#00ff6a",
                      fontSize: 14,
                      textAlign: "left",
                    }}
                  >
                    <b>IMPORTANT: </b>This is a LITE user, to make a FIAT
                    deposit to this user from the OTC Bridge you need to credit
                    the escrow account in Modulr and use the reference:{" "}
                    {userInfo.tracking_ref_escrow}
                  </Text>
                </Col>
              </>
            )}
        </Row>
        {loadingSearch && <Skeleton active />}
        {accountList.length > 0 && !loadingSearch && (
          <div
            id="table-history"
            className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
            style={{ marginTop: 10 }}
          >
            <Table
              rowKey={"_id"}
              rowClassName="row-table-deposit"
              dataSource={accountList}
              columns={columns}
              pagination={20}
            />
          </div>
        )}
      </Card>
      {modalTransactionOTC && (
        <ModalCreateTransactions
          modalTransactionOTC={modalTransactionOTC}
          openModalSend={openModalSendToOtc.bind(this)}
          closeModalSend={closeModalSendToOtc.bind(this)}
          userData={currentUser}
          accountData={selectedAccount}
          getAccounts={getAccounts.bind(this)}
        />
      )}
    </>
  );
};

export default BusinessBridge;

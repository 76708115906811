import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Image,
  List,
  Popover,
  Badge,
  Breadcrumb,
  message,
} from "antd";
import "./layouts.less";
import {
  DashboardOutlined,
  UserOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  RightOutlined,
  FundOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  PercentageOutlined,
  SwapOutlined,
  LockOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  CreditCardOutlined,
  PoweroffOutlined,
  GoldOutlined,
  ControlOutlined,
  FileSearchOutlined,
  AuditOutlined,
  ExperimentOutlined,
  BankOutlined,
} from "@ant-design/icons";
import FlagOutline from "@2fd/ant-design-icons/lib/FlagOutline";
import SwapHorizontalCircleOutline from "@2fd/ant-design-icons/lib/SwapHorizontalCircleOutline";
import Cash from "@2fd/ant-design-icons/lib/Cash";
import CurrencyBtc from "@2fd/ant-design-icons/lib/CurrencyBtc";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logoutUser, resetStore } from "../redux/actions/authActions";
import { currentToken } from "../redux/actions/tokenActions";
import { siderNavigate } from "../redux/actions/navigateActions";
import logo from "../images/logoDam.png";
let env = process.env.REACT_APP_ENV;
const { Header, Sider, Content } = Layout;
const DashboardLayout = (props) => {
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [roleUser, setRoleUser] = useState("");
  let history = useHistory();
  const { profileReducer } = useSelector((state) => ({
    profileReducer: state.profileReducer,
  }));
  const notifications = [
    {
      title: "New User is registered.",
      date: new Date(Date.now() - 10000000),
    },
    {
      title: "Application has been approved.",
      date: new Date(Date.now() - 50000000),
    },
  ];

  async function logoutUsr() {
    try {
      await props.logoutUser(props.clientId, props.token);
      history.push("/signin");
      props.resetStore();
      message.success("Succesfully Logged Out");
    } catch (error) {
      console.log("error", error);
    }
  }
  function currentOption(name, icon, key, link) {
    let option = {
      name: name,
      icon: icon,
      key: key,
    };
    props.siderNavigate(option);
    history.push(link);
  }
  useEffect(() => {
    const { role } = profileReducer.profile;
    if (role) {
      setRoleUser(role);
    }
  }, [profileReducer]);
  function filterOptions(options) {
    let available = [];
    for (let option of options) {
      if (option.key === "2") {
        if (env === "production") {
          if (roleUser === "COMPLIANCE" || roleUser === "SALES") {
            continue;
          } else {
            let availableChildrenOption = [];
            for (let child of option.children) {
              if (child.key === "sub2-1") {
                availableChildrenOption.push(child);
              }
            }
            option.children = availableChildrenOption;
            available.push(option);
          }
        } else {
          if (roleUser === "COMPLIANCE" || roleUser === "SALES") {
            continue;
          } else {
            available.push(option);
          }
        }
      } else if (option.key === "4") {
        if (env === "production") {
          if (roleUser !== "COMPLIANCE") {
            let availableChildrenOption = [];
            for (let child of option.children) {
              if (child.key === "sub4-0") {
                availableChildrenOption.push(child);
              }
            }
            option.children = availableChildrenOption;
            available.push(option);
          }
        } else {
          if (roleUser !== "COMPLIANCE") {
            available.push(option);
          }
        }
      } else if (option.key === "8") {
        if (roleUser === "ADMIN" || roleUser === "COMPLIANCE") {
          available.push(option);
        }
      } else if (option.key === "9") {
        if (roleUser === "ADMIN" || roleUser === "FINOPS") {
          available.push(option);
        }
      } else if (option.key === "6" || option.key === "7") {
        if (env !== "production") {
          available.push(option);
        }
      } else {
        available.push(option);
      }
    }
    return available;
  }
  const itemsUser = [
    {
      key: "userSignout",
      label: `Hi, ${profileReducer.profile?.email?.split("@")[0] || ""}`,
      className: "options",
      children: [
        {
          key: "profile",
          label: "Profile",
          icon: <UserOutlined style={{ marginRight: "4px" }} />,
          onClick: () =>
            currentOption("Profile", "UserOutlined", "profile", "/profile"),
        },
        {
          key: "signOut",
          label: "Sign out",
          icon: <PoweroffOutlined />,
          onClick: () => logoutUsr(),
        },
      ],
    },
  ];
  const options = [
    {
      key: "1",
      label: "Dashboard",
      icon: <DashboardOutlined />,
      onClick: () =>
        currentOption("Dashboard", "DashboardOutlined", "1", "/dashboard"),
    },
    {
      key: "2",
      label: "OTC",
      icon: <GoldOutlined />,
      children: [
        {
          key: "sub2-1",
          label: "Business bridge",
          icon: <ControlOutlined />,
          onClick: () =>
            currentOption(
              "Business bridge",
              "ControlOutlined",
              "2",
              "/businessBridge"
            ),
        },
        {
          key: "sub2-2",
          label: "Trade",
          icon: <SwapOutlined />,
          onClick: () =>
            currentOption("Trade", "SwapOutlined", "2-2", "/trade"),
        },
        {
          key: "sub2-3",
          label: "Trade Logs",
          icon: <FileSearchOutlined />,
          onClick: () =>
            currentOption(
              "Trade Logs",
              "FileSearchOutlined",
              "2-3",
              "/trade-logs"
            ),
        },
        {
          key: "sub2-4",
          label: "Analytics",
          icon: <LineChartOutlined />,
          onClick: () =>
            currentOption(
              "Analytics",
              "LineChartOutlined",
              "2-4",
              "/analytics"
            ),
        },
        {
          key: "sub2-5",
          label: "Comms Tool",
          icon: <PercentageOutlined />,
          onClick: () =>
            currentOption(
              "Comms Tool",
              "PercentageOutlined",
              "2-5",
              "/comms-tool"
            ),
        },
        {
          key: "sub2-6",
          label: "Monitoring",
          icon: <DashboardOutlined />,
          onClick: () =>
            currentOption(
              "Monitoring",
              "DashboardOutlined",
              "2-6",
              "/monitoring"
            ),
        },
      ],
    },
    {
      key: "3",
      label: "Transactions",
      icon: <FundOutlined />,
      onClick: () =>
        currentOption("Transactions", "FundOutlined", "3", "/transactions"),
    },
    {
      key: "4",
      label: "Finance",
      icon: <LineChartOutlined />,
      children: [
        {
          key: "sub4-0",
          label: "Commissions",
          icon: <Cash />,
          onClick: () =>
            currentOption("Commissions", "Cash", "sub4-0", "/commissions"),
        },
        {
          key: "sub4-1",
          label: "Fiat",
          icon: <DollarCircleOutlined />,
          onClick: () =>
            currentOption("Fiat", "DollarCircleOutlined", "sub4-1", "/fiat"),
        },
        {
          key: "sub4-2",
          label: "Crypto",
          icon: <CurrencyBtc />,
          onClick: () =>
            currentOption("Crypto", "CurrencyBtc", "sub4-2", "/crypto"),
        },
      ],
    },
    {
      key: "5",
      label: "Users",
      icon: <UserOutlined />,
      onClick: () => currentOption("Users", "UserOutlined", "4", "/users"),
    },
    {
      key: "6",
      label: "Support",
      icon: <CustomerServiceOutlined />,
      children: [
        {
          key: "sub6-1",
          label: "Cards",
          icon: <CreditCardOutlined />,
          onClick: () =>
            currentOption("Cards", "CreditCardOutlined", "sub6-1", "/cards"),
        },
      ],
    },
    {
      key: "7",
      label: "Configurations",
      icon: <SettingOutlined />,
      children: [
        {
          key: "sub7-1",
          label: "Deposits & Withdrawals",
          icon: <SwapHorizontalCircleOutline />,
          onClick: () =>
            currentOption(
              "Deposits & Withdrawals",
              "SwapHorizontalCircleOutline",
              "sub7-1",
              "/depositsWithdrawals"
            ),
        },
        {
          key: "sub7-2",
          label: "Exchange",
          icon: <SwapOutlined />,
          onClick: () =>
            currentOption("Exchange", "SwapOutlined", "sub7-2", "/exchange"),
        },
        {
          key: "sub7-3",
          label: "Damex challenges",
          icon: <FlagOutline />,
          onClick: () =>
            currentOption("Damex challenges", "FlagOutline", "sub7-3", "/#"),
        },
        {
          key: "sub7-4",
          label: "Freeze withdrawals",
          icon: <LockOutlined />,
          onClick: () =>
            currentOption(
              "Freeze withdrawals",
              "LockOutlined",
              "sub7-4",
              "/freeze"
            ),
        },
        {
          key: "sub7-5",
          label: "Team",
          icon: <TeamOutlined />,
          onClick: () =>
            currentOption("Team", "TeamOutlined", "sub7-5", "/team"),
        },
        {
          key: "sub7-6",
          label: "Cards",
          icon: <CreditCardOutlined />,
          onClick: () =>
            currentOption("Cards", "CreditCardOutlined", "sub7-6", "/cards"),
        },
      ],
    },
    {
      key: "8",
      label: "Compliance",
      icon: <AuditOutlined />,
      children: [
        {
          key: "sub8-1",
          label: "AML Screening",
          icon: <FileSearchOutlined />,
          onClick: () =>
            currentOption("AML Screening", "Cash", "sub8-1", "/screening"),
        },
      ],
    },
    {
      key: "9",
      label: "Operations",
      icon: <ExperimentOutlined />,
      children: [
        {
          key: "sub9-1",
          label: "Pending settlements",
          icon: <BankOutlined />,
          onClick: () =>
            currentOption(
              "Pending settlements",
              "BankOutlined",
              "sub9-1",
              "/pending-settlements"
            ),
        },
      ],
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsedMenu}
        className={collapsedMenu ? "sider-collapsed" : "sider-style"}
      >
        <div className="logo">
          <Image preview={false} width={55} src={logo} />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={props.header.key}
          items={filterOptions(options)}
        />
      </Sider>

      <Layout className="site-layout">
        <Header className="header-layout">
          {React.createElement(
            collapsedMenu ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsedMenu(!collapsedMenu),
            }
          )}
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Popover
              placement="bottomRight"
              trigger="click"
              key="notifications"
              content={
                <div className="notification">
                  <List
                    itemLayout="horizontal"
                    dataSource={notifications}
                    locale={{
                      emptyText: "You have viewed all notifications",
                    }}
                    renderItem={(item) => (
                      <List.Item className="notification-item">
                        <List.Item.Meta
                          className="notification-text"
                          title={item.title}
                          description={moment(item.date).fromNow()}
                        />
                        <RightOutlined
                          style={{
                            fontSize: 10,
                            color: "#ccc",
                            marginLeft: "15px",
                            marginTop: "18px",
                          }}
                        />
                      </List.Item>
                    )}
                  />
                  {notifications.length ? (
                    <div
                      // onClick={onAllNotificationsRead}
                      className="clear-button"
                    >
                      Clear notifications
                    </div>
                  ) : null}
                </div>
              }
            >
              <Badge
                count={notifications.length}
                dot
                offset={[-10, 10]}
                className="icon-button"
              >
                <BellOutlined className="icon-font" />
              </Badge>
            </Popover>
            <Menu
              key="user"
              mode="horizontal"
              style={{ direction: "rtl", width: "auto", paddingRight: "29px" }}
              items={itemsUser}
            />
          </div>
        </Header>
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          <Breadcrumb>
            <Breadcrumb.Item style={{ color: "#fff", fontSize: 30 }}>
              {" " + props.header.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content
          className="site-layout-background"
          style={{
            padding: 20,
            minHeight: 280,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    logoutResult: state.authUser.logout,
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    header: state.navigate.pageNavigate,
  };
};

const mapDispatchToProps = {
  logoutUser,
  currentToken,
  resetStore,
  siderNavigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);

// Dependencies
import React from "react";
import { connect } from "react-redux";

// Ant Design Components
import { Tabs, Typography } from "antd";

// Components
import PendingSettlementsTable from "./components/PendingSettlementTable";
import BalanceCards from "./components/BalanceCards";

// Hooks
import { useHighValueBalance } from './hooks/useHighValueBalance';
import usePendingSettlementBalance from "./hooks/usePendingSettlementBalance";

// Actions
import { currentToken } from "../../redux/actions/tokenActions";

// Styles
import "./pendingSettlements.less";

const { Text } = Typography;

// -----------------------------------------------------------------------------
// @ Pending Settlements Component
// -----------------------------------------------------------------------------
const PendingSettlements = () => {
  const { loading, tradingAccounts, withdrawalVault, refreshBalances } = useHighValueBalance();
  const { 
    loading: pendingSettlementLoading, 
    pendingSettlementBalances,
    refetch: refreshPendingBalances 
  } = usePendingSettlementBalance();

  // Tab items configuration with balance cards and settlements table
  const tabItems = [
    {
      key: "1",
      label: <Text style={{ fontSize: 18, color: "#fff" }}>Damex Direct</Text>,
      children: (
        <>
          {/* Balance information cards section */}
          <BalanceCards 
            loading={loading || pendingSettlementLoading}
            tradingAccounts={tradingAccounts}
            withdrawalVault={withdrawalVault}
            pendingSettlementBalances={pendingSettlementBalances}
          />
          {/* Pending settlements table section */}
          <PendingSettlementsTable 
            refreshBalances={refreshBalances}
            refreshPendingBalances={refreshPendingBalances}
            source="Damex Direct" 
          />
        </>
      ),
    },
  ];

  return (
    <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane={true} />
  );
};

// -----------------------------------------------------------------------------
// @ Redux Connection
// -----------------------------------------------------------------------------

// Map Redux state to component props
const mapStateToProps = (state) => ({
  token: state.tokenAccess.current,
  clientId: state.tokenAccess.clientId,
});

// Map Redux actions to component props
const mapDispatchToProps = {
  currentToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSettlements);

import axios from "axios";
import config from "./config";

const BASE_URL = config.URL;

const pendingSettlements = {
  async getTransactions(idToken, accessToken, filter) {
    const resp = await axios.get(BASE_URL + config.highValueTransactions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        entity: filter?.entity || null,
        currency_code: filter?.currency_code || null,
        min_date: filter?.min_date || null,
        max_date: filter?.max_date || null,
        timezone_offset: filter?.timezone_offset || null,
        user_email: filter?.user_email || null,
        user_fullname: filter?.user_fullname || null,
        user_id: filter?.user_id || null,
        transaction_id: filter?.transaction_id || null,
        type: filter?.type || null,
        last_id: filter?.last_id || null,
        platform: "BUSINESS"
      },
    });
    return resp;
  },

  async approveTransaction(idToken, accessToken, transactionId) {
    const resp = await axios.post(
      `${BASE_URL}${config.highValueTransactions}/${transactionId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp.data;
  },

  async getBalance(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.highValueBalance, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },

  async getPendingSettlementBalance(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.pendingSettlementBalance, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        platform: "BUSINESS"
      }
    });
    return resp;
  },
};

export default pendingSettlements; 
// Dependencies
import React from "react";
import NumberFormat from "react-number-format";

// Ant Design Components
import { Card, Row, Col, Typography, Skeleton } from "antd";
import { Divider } from "antd";

// Constants
import { asset } from "../../../common/assets";

// Styles
import "../pendingSettlements.less";

const { Text } = Typography;

const BalanceCard = ({ title, balances, loading, groupByEntity }) => {
  // Helper function to get asset icon from currency code
  const getAssetIcon = (currency) => {
    const assetInfo = asset.assets.find((a) => a.value === currency);
    return assetInfo?.icon || null;
  };

  // Helper function to render individual balance item
  const renderBalance = (item) => {
    if (!item) return null;

    return (
      <Col
        xs={24}
        sm={24}
        key={`${item.currency}${item.network || ""}`}
      >
        <div className="balance-item">
          {/* Currency Info Section */}
          <div className="currency-info">
            <img
              src={getAssetIcon(item.currency)}
              alt={item.currency}
              className="currency-icon"
            />
            <Text className="currency-text">
              {item.currency}
              {item.network && item.network !== "BASE_ASSET" && (
                <span className="network-text">({item.network})</span>
              )}
            </Text>
          </div>

          {/* Balance Amount Section */}
          <NumberFormat
            value={item.balance}
            displayType="text"
            thousandSeparator={true}
            decimalScale={
              item.currency === "BTC" || item.currency === "ETH" ? 8 : 2
            }
            className="balance-amount"
            renderText={(value) => (
              <Text className="balance-amount">{value}</Text>
            )}
          />
        </div>
      </Col>
    );
  };

  const renderTradingBalances = (balances) => {
    if (!balances || !Array.isArray(balances)) {
      return null;
    }

    if (groupByEntity && balances[0]?.entity) {
      return balances.map(({ entity, balances: entityBalances }, index) => (
        <React.Fragment key={entity}>
          <Col span={24}>
            <Divider
              variant="dashed"
              orientation="left"
              style={{
                borderColor: "#fff",
                color: "#fff",
                margin: index === 0 ? "0 0 8px 0" : "8px 0",
              }}
              dashed
            >
              {entity}
            </Divider>
          </Col>
          {Array.isArray(entityBalances) && entityBalances.map(renderBalance)}
        </React.Fragment>
      ));
    }

    return balances.map(renderBalance);
  };

  return (
    <Card
      title={<Text className="card-title">{title}</Text>}
      className="balance-card"
      bordered={false}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 8]}>{renderTradingBalances(balances)}</Row>
      )}
    </Card>
  );
};

const BalanceCards = ({
  loading,
  tradingAccounts,
  withdrawalVault,
  pendingSettlementBalances,
}) => {
  // Helper function to group fiat balances by entity
  const groupFiatBalancesByEntity = (balances) => {
    if (!balances) return [];

    const groupedByEntity = balances.reduce((acc, item) => {
      if (!acc[item.entity]) {
        acc[item.entity] = {
          entity: item.entity,
          balances: [],
        };
      }
      acc[item.entity].balances.push({
        currency: item.currency,
        balance: item.balance,
      });
      return acc;
    }, {});

    return Object.values(groupedByEntity);
  };

  return (
    <Row
      gutter={[16, 16]}
      className="balance-cards-container"
    >
      {/* Withdrawal Vault Balance Card */}
      <Col
        xs={24}
        md={12}
        xxl={6}
      >
        <BalanceCard
          title="High Value Crypto Withdrawal Vault"
          balances={withdrawalVault || []}
          loading={loading}
        />
      </Col>

      {/* Trading Accounts Balance Card */}
      <Col
        xs={24}
        md={12}
        xxl={6}
      >
        <BalanceCard
          title="High Value FIAT Trading Accounts"
          balances={tradingAccounts || []}
          loading={loading}
          groupByEntity
        />
      </Col>

      {/* Crypto Pending Settlements Balance Card */}
      <Col
        xs={24}
        md={12}
        xxl={6}
      >
        <BalanceCard
          title="Crypto Pending Settlements Balances"
          balances={
            pendingSettlementBalances?.crypto_pending_settlement_balances?.map(
              (item) => ({
                currency: item.currency,
                balance: item.balances[0].balance,
                network:
                  item.balances[0].network !== "BASE_ASSET"
                    ? item.balances[0].network
                    : undefined,
              })
            ) || []
          }
          loading={loading}
        />
      </Col>

      {/* Fiat Pending Settlements Balance Card */}
      <Col
        xs={24}
        md={12}
        xxl={6}
      >
        <BalanceCard
          title="Fiat Pending Settlement Balances"
          balances={groupFiatBalancesByEntity(
            pendingSettlementBalances?.fiat_pending_settlement_balances
          )}
          loading={loading}
          groupByEntity
        />
      </Col>
    </Row>
  );
};

export default BalanceCards;

import React from "react";
import { Table } from "antd";
import { Card, Typography, Select, Row, Col, Button, Input } from "antd";
import "./crypto.less";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
const { Text } = Typography;
const { Option } = Select;

const WithdrawalVaultsTable = (props) => {
  const dataSource = [];
  dataSource.push(
    {
      id: 1234,
      key: "abcd12345678",
      balance: 5.4,
      currency: "BTC",
      account:
        "Account Holder: Damex / Account number: 70528379 / Sort Code: 000000",
      address: "0xA248826f7739b",
      status: "ACTIVE",
      partner: "Fireblocks",
      fee: 0.00015,
      pending: 1,
      locked: 3,
    },
    {
      id: 2097,
      key: "xyz12345678",
      balance: 10,
      currency: "BNB",
      account:
        "Account Holder: Damex / BIC: MOCKGB21 / IBAN: GB51MOCK00000070528380",
      address: "7EEd4918A1De3F4cce7585003FC",
      status: "INACTIVE",
      partner: "Fireblocks",
      fee: 0.00015,
      pending: 3,
      locked: 1,
    }
  );
  const currencies = asset.assets.filter((element) => element.type === "FIAT");
  currencies.push({ value: "ALL", name: "All" });
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Vault ID
        </Text>
      ),
      dataIndex: "id",
      key: "id",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Name ID
        </Text>
      ),
      dataIndex: "key",
      key: "key",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Balance
        </Text>
      ),
      dataIndex: "balance",
      key: "balance",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.balance}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Pending Amount
        </Text>
      ),
      dataIndex: "pending",
      key: "pending",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.pending}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Locked Amount
        </Text>
      ),
      dataIndex: "locked",
      key: "locked",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.locked}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Partner
        </Text>
      ),
      dataIndex: "partner",
      key: "partner",
    },
  ];
  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            placeholder="Currency"
            style={{ width: "100%" }}
            size="large"
          >
            {currencies.map((item, i) => {
              return (
                <Option key={i} value={item.value}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="Name ID"
          ></Input>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Partner">
            <Option key="partner" value={"FIREBLOCK"}>
              Fireblock
            </Option>
          </Select>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          xl={{ span: 3, offset: 0 }}
          xxl={{ span: 3, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Filter
          </Button>
        </Col>
      </Row>

      <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        <Table
          rowClassName="row-table"
          dataSource={dataSource}
          scroll={{ x: 1300 }}
          columns={columns}
          pagination={{ pageSize: 20 }}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ marginLeft: 44 }}>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  Address: {record.address}
                </Text>
              </p>
            ),
          }}
        />
      </div>
    </Card>
  );
};

export default WithdrawalVaultsTable;

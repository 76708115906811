// Dependencies
import { message } from "antd";
import { useState } from "react";

// Services
import pendingSettlements from "../../../services/pendingSettlements";

export const useApproveTransaction = (
  clientId,
  token,
  refreshData,
  refreshBalances,
  refreshPendingBalances
) => {
  // State to track approval process
  const [isApproving, setIsApproving] = useState(false);

  // Handler for approving a transaction
  const handleApprove = async (id) => {
    setIsApproving(true);
    try {
      // Call service to approve transaction
      await pendingSettlements.approveTransaction(clientId, token, id);
      message.success("Transaction approved successfully");

      // Refresh all balance data after approval
      await Promise.all([
        refreshData(),
        refreshBalances(),
        refreshPendingBalances()
      ]);
    } catch (error) {
      // Handle errors based on response status
      if (error.response) {
        switch (error.response.status) {
          case 400:
            message.error("Invalid transaction or insufficient balance");
            break;
          case 403:
            message.error("Permission denied");
            break;
          case 404:
            message.error("Transaction not found");
            break;
          default:
            message.error("An error occurred while approving the transaction");
        }
      }
    } finally {
      setIsApproving(false);
    }
  };

  return { handleApprove, isApproving };
};

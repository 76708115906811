import React from "react";
import { Tag } from "antd";

const statusUserStyle = {
  WAITING_FOR_KYC: {
    color: "#D48806",
    background: "#FFFBE6",
    borderColor: "#FFE58F",
  },
  PENDING_REVIEW: {
    color: "#531DAB",
    background: "#F9F0FF",
    borderColor: "#D3ADF7",
  },
  COUNTRY_NOT_SUPPORTED: {
    color: "#D4380D",
    background: "#FFF2E8",
    borderColor: "#FFBB96",
  },
  REJECTED: { color: "#CF1322", background: "#FFF1F0", borderColor: "#FFA39E" },
  RETRY_KYC: {
    color: "#08979C",
    background: "#E6FFFB",
    borderColor: "#87E8DE",
  },
  APPROVED: { color: "#389E0D", background: "#F6FFED", borderColor: "#B7EB8F" },
  ACTIVE: { color: "#096DD9", background: "#E6F7FF", borderColor: "#91D5FF" },
  KYC_RENEWAL: {
    color: "#597ef7",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
  },
  INACTIVE: { color: "#C41D7F", background: "#FFF0F6", borderColor: "#FFB6D9" },
};
const statusText = {
  WAITING_FOR_KYC: "WAITING FOR KYC",
  PENDING_REVIEW: "PENDING REVIEW",
  COUNTRY_NOT_SUPPORTED: "COUNTRY NOT SUPPORTED",
  REJECTED: "REJECTED",
  RETRY_KYC: "RETRY KYC",
  APPROVED: "APPROVED",
  ACTIVE: "ACTIVE",
  KYC_RENEWAL: "KYC RENEWAL",
  INACTIVE: "INACTIVE",
};
const StatusUserTag = ({ status }) => {
  const style = statusUserStyle[status] || {};
  const displayText = statusText[status] || status.replaceAll("_", " ");

  return (
    <Tag
      style={{
        fontSize: 12,
        ...style,
      }}
    >
      {displayText}
    </Tag>
  );
};

export default StatusUserTag;

export const COLUMNS = [
  {
    title: "Internal ID",
    dataIndex: "_id",
    key: "_id",
    width: 150,
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
    width: 150,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 120,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    width: 100,
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: 100,
  },
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
    key: "transaction_id",
    width: 150,
  },
  {
    title: "Date",
    dataIndex: "transaction_date",
    key: "transaction_date",
    width: 150,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 120,
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    width: 100,
  },
  {
    title: "Bank Entity",
    dataIndex: "account_entity",
    key: "account_entity",
    width: 120,
  },
];

export const EXPANDED_FIELDS = [
  { label: "Entity", key: "entity" },
  { label: "Exchange User Rate", key: "exchange_user_rate" },
  { label: "Fee", key: "fee" },
  { label: "Fiat Customer ID", key: "fiat_customer_id" },
  { label: "Spread Fee", key: "spread_fee" },
  { label: "Spread Percentage", key: "spread_percentage" },
  { label: "User Email", key: "user_email" },
  { label: "User ID", key: "user_id" },
  { label: "Vault Account ID", key: "vault_account_id" },
];


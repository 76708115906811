import React, { useEffect, useState } from "react";
import { Row, Tabs, Typography, Spin, message } from "antd";
import BalanceTable from "./balanceTable";
import FirstRowCards from "./firstRowCards";
import VaultsTable from "./vaultsTable";
import FiatBalanceTable from "./fiatBalanceTable";
import FiatVaultsTable from "./fiatVaultsTable";
import { connect, useSelector } from "react-redux";
import { getBalance } from "../../redux/actions/balanceActions";
import { getCurrencies } from "../../redux/actions/otcActions";
import { currentToken } from "../../redux/actions/tokenActions";
import servicesBalance from "../../services/balance";
import servicesOtc from "../../services/otc";
import { useHistory } from "react-router-dom";

import "./dashboard.less";

const { Text } = Typography;

const Dashboard = (props) => {
  const [balanceFlag, setBalanceFlag] = useState(false);
  // const [spinFlag, setSpinFlag] = useState(true);

  // const [global, setGlobal] = useState([]);
  const [retail, setRetail] = useState([]);
  const [business, setBusiness] = useState([]);
  const history = useHistory();

  let balance = [];

  const { token } = useSelector((state) => ({
    token: state.tokenAccess.current,
  }));
  async function getSupportedCurrencies() {
    try {
      const response = await servicesOtc.getCurrencies(props.clientId, token);
      props.currentToken(response.headers["x-new-access-token"]);
      if (response.status === 200) {
        let currencies = response.data.data?.currencies;
        await props.getCurrencies(currencies);
      }
    } catch (error) {
      //console.log(error);
      if (error.response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(error.response.headers["x-new-access-token"]);
        if (error.response.status === 401 || error.response.status === 422) {
          message.error("An error has ocurred. Please sign in again");
          window.localStorage.setItem("success", false);
          history.push("/signin");
        }
      }
    }
  }
  async function gtBalance() {
    try {
      const response = await servicesBalance.balance(props.clientId, token);
      await props.getBalance(response);
      props.currentToken(response.headers["x-new-access-token"]);
      if (response.status === 200 && !balanceFlag) {
        balance = response.data.data.platforms;
        //    setGlobal(balance[0]);
        setBusiness(balance[1]);
        setRetail(balance[2]);
        setBalanceFlag(true);
        // setSpinFlag(false);
      }
    } catch (error) {
      setBalanceFlag(false);
      //  setSpinFlag(false);
      if (error.response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(error.response.headers["x-new-access-token"]);
        if (error.response.status === 401 || error.response.status === 422) {
          message.error("An error has ocurred. Please sign in again");
          window.localStorage.setItem("success", false);
          history.push("/signin");
        }
      }
    }
  }

  useEffect(() => {
    if (!balanceFlag) {
      gtBalance();
      getSupportedCurrencies();
    } else {
      // setGlobal(props.balanceResult.data.data.platforms[0]);
      setBusiness(props.balanceResult.data.data.platforms[1]);
      setRetail(props.balanceResult.data.data.platforms[2]);
    }
    return () => {
      // setGlobal([]);
      setBusiness([]);
      setRetail([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const itemsTab = [
    // {
    //   key: "1",
    //   label: (
    //     <Text
    //       style={{
    //         fontSize: 18,
    //         color: "#fff",
    //       }}
    //     >
    //       Global
    //     </Text>
    //   ),
    //   children: (
    //     <>
    //       <FirstRowCards activeTab="1" />
    //       {balanceFlag && !spinFlag ? (
    //         <>
    //           <Row
    //             gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
    //             className="row"
    //           >
    //             <BalanceTable data={global} />
    //             <VaultsTable data={global} />
    //           </Row>
    //           <Row
    //             gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
    //             className="row"
    //           >
    //             <FiatBalanceTable data={global} />
    //             <FiatVaultsTable data={global} />
    //           </Row>
    //         </>
    //       ) : !balanceFlag && spinFlag ? (
    //         <div
    //           style={{
    //             width: "100%",
    //             margin: "20px 0",
    //             marginBottom: "20px",
    //             padding: "30px 50px",
    //             textAlign: "center",
    //           }}
    //         >
    //           <Spin size="large" tip="Loading..." />
    //         </div>
    //       ) : (
    //         <div style={{ textAlign: "center", padding: "40px" }}>
    //           <h3 style={{ fontWeight: "bold" }}>
    //             An error has ocurred. Please try again
    //           </h3>
    //         </div>
    //       )}
    //     </>
    //   ),
    // },
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: (
        <>
          <FirstRowCards activeTab="3" />
          {balanceFlag ? (
            <>
              <Row
                gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
                className="row"
              >
                <BalanceTable data={business} />
                <VaultsTable data={business} />
              </Row>
              <Row
                gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
                className="row"
              >
                <FiatBalanceTable data={business} />
                <FiatVaultsTable data={business} />
              </Row>
            </>
          ) : (
            <Spin />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: (
        <>
          <FirstRowCards activeTab="2" />
          {balanceFlag ? (
            <>
              <Row
                gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
                className="row"
              >
                <BalanceTable data={retail} />
                <VaultsTable data={retail} />
              </Row>
              <Row
                gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
                className="row"
              >
                <FiatBalanceTable data={retail} />
                <FiatVaultsTable data={retail} />
              </Row>
            </>
          ) : (
            <Spin />
          )}
        </>
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={itemsTab} />;
};

const mapStateToProps = (state) => {
  return {
    balanceResult: state.balance.balanceResult,
    //token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    //headers: state.balance.headers,
  };
};

const mapDispatchToProps = {
  getBalance,
  currentToken,
  getCurrencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

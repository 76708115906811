import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import {
  Card,
  Typography,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
  Input,
  message,
  Skeleton,
  Divider,
  Space,
} from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "./transactions.less";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
  getTransactions,
  clearTransactions,
  clearDataSource,
  getRules,
} from "../../redux/actions/transactionActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { usersDamex, clearUsersDamex } from "../../redux/actions/otcActions";
import { getUsersReviewersAml } from "../../redux/actions/userActions";
import servicesTransactions from "../../services/transaction";
import servicesUsers from "../../services/user";
import { useHistory } from "react-router-dom";
import moment from "moment";
import StatusTag from "../common/StatusTag";
const URL_ETH =
  process.env.REACT_APP_ENV === "production"
    ? "https://etherscan.io/tx/"
    : "https://goerli.etherscan.io/tx/";
const URL_BTC =
  process.env.REACT_APP_ENV === "production"
    ? "https://live.blockcypher.com/btc/tx/"
    : "https://live.blockcypher.com/btc-testnet/tx/";
const URL_TRON =
  process.env.REACT_APP_ENV === "production"
    ? "https://tronscan.org/#/transaction/"
    : "https://shasta.tronscan.org/#/transaction/";
const { Text, Link } = Typography;
const { Option } = Select;
const TransactionsTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [flag, setFlag] = useState(true);
  const [filter, setFilter] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [loadingTx, setLoadingTx] = useState(false);
  const [txDetail, setTxDetail] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastIdUsers, setLastIdUsers] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [showButtonLoad, setShowButtonLoad] = useState(true);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [lastIdTx, setlastIdTx] = useState(null);
  const [loadingReviewer, setLoadingReviewer] = useState(false);
  const [amlReviewer, setAmlReviewer] = useState([]);
  const [loadingRules, setLoadingRules] = useState(false);
  const [rulesAmlList, setRulesAmlList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const {
    token,
    usersListResp,
    transactionList,
    usersReviewersList,
    rulesList,
  } = useSelector((state) => ({
    token: state.tokenAccess,
    usersListResp: state.otc.users,
    transactionList: state.transaction.transactionResponse,
    usersReviewersList: state.users.usersReviewers,
    rulesList: state.transaction.rulesResp,
  }));
  const status = [
    {
      key: "INITIATED",
      value: "Initiated",
    },
    {
      key: "PENDING",
      value: "Pending",
    },
    {
      key: "PROCESSING",
      value: "Processing",
    },
    {
      key: "COMPLETED",
      value: "Completed",
    },
    {
      key: "REJECTED",
      value: "Rejected",
    },
    {
      key: "FAILED",
      value: "Failed",
    },
    { key: "CLIENT_APPROVAL_PENDING", value: "Approval pending" },
    { key: "APPROVAL_EXPIRED", value: "Approval expired" },
    { key: "AML_HOLD", value: "AML HOLD" },
    { key: "AML_FREEZE", value: "AML FREZEE" },

    { key: "ALL", value: "All" },
  ];
  const transactionType = [
    { key: "DEPOSIT", value: "Deposit" },
    { key: "TRANSFER_IN", value: "Transfer in" },
    { key: "TRANSFER_IN_VERASITY", value: "Transfer in Verasity" },
    { key: "EXCHANGE_IN", value: "Exchange in" },
    { key: "DEBIT_CARD_REVERSAL", value: "Debit Card Reversal" },
    { key: "DEBIT_CARD_REFUND", value: "Debit Card Refund" },
    { key: "CASH_BACK", value: "Cash Back" },
    { key: "REFERRAL_BONUS", value: "Referral Bonus" },
    { key: "DAMEX_PLAN_EXPIRATION", value: "Damex Plan Expiration" },
    { key: "WITHDRAWAL", value: "Withdrawal" },
    { key: "TRANSFER_OUT", value: "Transfer out" },
    { key: "TRANSFER_OUT_VERASITY", value: "Transfer out Verasity" },
    { key: "EXCHANGE_OUT", value: "Exchange out" },
    { key: "DEBIT_CARD_PAYMENT", value: "Debit Card Payment" },
    { key: "CARD_DELIVERY_CHARGES", value: "Card Delivery Charges" },
    { key: "DAMEX_PLAN_ACTIVATION", value: "Damex Plan Activation" },
    {
      key: "IN_YIELDING_EARLY_REDEMPTION",
      value: "In Yielding Early Redemption",
    },
    {
      key: "IN_YIELDING_REWARDS",
      value: "In Yielding Rewards",
    },
    {
      key: "IN_YIELDING_EXPIRATION",
      value: "In Yielding Expiration",
    },
    { key: "IN_YIELDING_ACTIVATION", value: "In Yielding Activation" },
    { key: "REBALANCE_IN", value: "Rebalance in" },
    { key: "REBALANCE_OUT", value: "Rebalance out" },
    { key: "ALL", value: "All" },
  ];
  const transactionTypeList = transactionType.filter(
    (element) =>
      element.key !== "IN_YIELDING_EARLY_REDEMPTION" &&
      element.key !== "IN_YIELDING_ACTIVATION" &&
      element.key !== "IN_YIELDING_REWARDS" &&
      element.key !== "IN_YIELDING_EXPIRATION"
  );
  const entityList = [
    { key: "GIB", value: "GIB" },
    { key: "UAB", value: "UAB" },
    { key: "ALL", value: "All" },
  ];
  const amlScreenedList = [
    { key: true, value: "Yes" },
    { key: false, value: "No" },
    { key: "ALL", value: "All" },
  ];
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Internal ID
        </Text>
      ),
      width: 150,
      dataIndex: "_id",
      key: "_id",
      className: "id-column",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Date
        </Text>
      ),
      width: 140,
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          User ID
        </Text>
      ),
      width: 150,
      dataIndex: "user_id",
      key: "user_id",
      className: "id-column",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          User email
        </Text>
      ),
      width: 130,
      dataIndex: "user_email",
      key: "user_email",
      render: (render, record) => (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
            fontWeight: 400,
            marginBottom: 0,
          }}
        >
          {record.user_email}
        </Text>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          User Name
        </Text>
      ),
      //width: "12%",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Entity
        </Text>
      ),
      width: 80,
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Country of residence
        </Text>
      ),
      width: 105,
      dataIndex: "country",
      key: "country",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      width: 110,
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Tx ID
        </Text>
      ),
      dataIndex: "transaction_id",
      key: "transaction_id",
      className: "id-column",
      render: (record, originNode) => <>{originNode.transaction_id}</>,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Type
        </Text>
      ),
      width: 110,
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {findTypeTransaction(type)?.value !== undefined
            ? findTypeTransaction(type).value
            : type}
        </Text>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Amount
        </Text>
      ),
      //width: "13%",
      dataIndex: "amount",
      key: "amount",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.amount}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Fee
        </Text>
      ),
      //width: "10%",
      dataIndex: "fee",
      key: "fee",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.fee}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Status
        </Text>
      ),
      //width: "12%",
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Platform
        </Text>
      ),
      //width: "14%",
      className: props.source === "Global" ? "" : "deposit-column-hidden",
      dataIndex: "platform",
      key: "platform",
    },
  ];
  let lastId = null;
  let transaction = [];
  //let transactionsData = [];
  function getCurrencies() {
    setCurrenciesList([]);
    let currencies = props.supportedCurrencies;
    if (currencies) {
      setCurrenciesList(currencies);

      if (props.source === "Damex Direct") {
        currencies = currencies.filter((element) =>
          element.platforms.includes("BUSINESS")
        );
      } else if (props.source === "Damex") {
        currencies = currencies.filter((element) =>
          element.platforms.includes("RETAIL")
        );
      }

      currencies = currencies.concat({ code: "ALL", name: "All" });
      setCurrenciesList(currencies);
    }
  }
  useEffect(() => {
    getCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source]);

  useEffect(() => {
    setRowId("");
    getTransactionsList();
    if (props.source === "Damex Direct") {
      getUsers();
      getAmlReviewers();
      getRulesFtm();
    }
    const entries = performance.getEntriesByType("navigation");
    entries.forEach((entry) => {
      if (entry.type === "reload") {
        dispatch(clearDataSource());
      }
    });

    return () => {
      setLoading(false);
      dispatch(clearUsersDamex());
      dispatch(clearTransactions());
      dispatch(clearDataSource());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let keys = Object.keys(transactionList);
    if (keys.length > 0 && rowId === null) {
      formatDataTx();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionList]);
  useEffect(() => {
    let keys = Object.keys(usersListResp);
    if (keys.length > 0) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersListResp]);
  useEffect(() => {
    let keys = Object.keys(usersReviewersList);
    let usersResp = [];
    if (keys.length > 0) {
      let data = usersReviewersList.data?.data?.users;
      if (data !== undefined) {
        usersResp = [...data, ...[{ email: "All", _id: "ALL" }]];
        setAmlReviewer(usersResp);
      }
      setLoadingReviewer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReviewersList]);
  useEffect(() => {
    let keys = Object.keys(rulesList);
    if (keys.length > 0) {
      let data = rulesList.data?.data?.fiat_transaction_monitoring_rules;
      if (data !== undefined) {
        let rules = [...data, ...[{ rule_id: "ALL", _id: "ALL" }]];
        setRulesAmlList(rules);
      }
      setLoadingRules(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesList]);
  const handleInput = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  function addID(aux) {
    let data = [];
    aux.map((item, i) => {
      return data.push({ ...item, key: item._id });
    });
    return data;
  }

  async function getTransactionsList(isFilter) {
    setFlag(true);
    setRowId(null);
    if (isFilter) {
      setIsFiltering(true);
      lastId = null;
      setDataSource([]);
      setPagination({ current: 1, pageSize: 20, total: 0 });
    } else {
      lastId = lastIdTx;
    }
    let filterAux = {};
    if (props?.source === "Global") {
      filterAux = {
        ...filter,
        platform: "",
        last_id: lastId,
      };
    } else if (props?.source === "Damex") {
      filterAux = {
        ...filter,
        platform: "RETAIL",
        last_id: lastId,
      };
    } else {
      filterAux = {
        ...filter,
        platform: "BUSINESS",
        last_id: lastId,
      };
    }
    try {
      const response = await servicesTransactions.transaction(
        props.clientId,
        props.token,
        filterAux
      );

      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      await props.getTransactions(response);
    } catch (error) {
      console.log(error);
      setFlag(false);
      props.currentToken(error.response.headers["x-new-access-token"]);
      setDataSource([]);
      if (error.response.status === 401 || error.response.status === 422) {
        message.error("An error has ocurred. Please sign in again");
        window.localStorage.setItem("success", false);
        history.push("/signin");
      }
    }
  }
  async function getAmlReviewers() {
    setLoadingReviewer(true);
    try {
      const response = await servicesUsers.usersReviewers(
        token.clientId,
        token.current
      );

      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      dispatch(getUsersReviewersAml(response));
    } catch (error) {
      console.log(error);
      props.currentToken(error.response.headers["x-new-access-token"]);
      setAmlReviewer([]);
      setLoadingReviewer(false);
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 422
      ) {
        message.error("An error has ocurred. Please sign in again");
        window.localStorage.setItem("success", false);
        history.push("/signin");
      } else {
        message.error("An error has ocurred, please try again");
      }
    }
  }
  async function getRulesFtm() {
    setLoadingRules(true);
    try {
      const response = await servicesTransactions.getRulesFtm(
        token.clientId,
        token.current
      );

      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      dispatch(getRules(response));
    } catch (error) {
      console.log(error);
      props.currentToken(error.response.headers["x-new-access-token"]);
      setRulesAmlList([]);
      setLoadingRules(false);
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 422
      ) {
        message.error("An error has ocurred. Please sign in again");
        window.localStorage.setItem("success", false);
        history.push("/signin");
      } else {
        message.error("An error has ocurred, please try again");
      }
    }
  }
  function formatDataTx() {
    let aux = [];
    let response = transactionList;
    if (response.status === 200) {
      transaction = response.data.data;
      if (transaction?.transactions?.length > 0) {
        setFilter({ ...filter, last_id: transaction.current_last_id });
        setlastIdTx(transaction.current_last_id);
        let data = addID(transaction.transactions);
        aux = [...dataSource, ...data];

        let newPagination = {
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: aux.length + 1,
        };
        setPagination(newPagination);
        setDataSource(aux);
      } else {
        let newPagination = {
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.length,
        };
        setPagination(newPagination);
      }
      setFlag(false);
      setIsFiltering(false);
      return aux;
    }
  }
  function findTypeTransaction(type) {
    let data = transactionType.find((element) => element.key === type);
    return data;
  }
  async function handleTableChange(pag) {
    setPagination(pag);
    let currentSize = pagination.current * pagination.pageSize + 1;
    if (pagination.current < pag.current && currentSize === pag.total) {
      await getTransactionsList(false);
    }
  }

  async function getTxDetail(expanded, record) {
    setTxDetail({});
    if (
      record.type === "EXCHANGE_IN" ||
      record.type === "EXCHANGE_OUT" ||
      record.type === "TRANSFER_IN" ||
      record.type === "TRANSFER_OUT"
    ) {
      let typeTransaction = "";
      if (record.type === "EXCHANGE_OUT") {
        typeTransaction = "EXCHANGE_IN";
      } else if (record.type === "EXCHANGE_IN") {
        typeTransaction = "EXCHANGE_OUT";
      } else if (record.type === "TRANSFER_IN") {
        typeTransaction = "TRANSFER_OUT";
      } else if (record.type === "TRANSFER_OUT") {
        typeTransaction = "TRANSFER_IN";
      }

      let txId = { transaction_id: record.transaction_id };
      try {
        if (record.transaction_id !== null) {
          setLoadingTx(true);
          const resp = await servicesTransactions.transaction(
            props.clientId,
            props.token,
            txId
          );
          await props.getTransactions(resp);
          props.currentToken(resp.headers["x-new-access-token"]);

          if (resp.status === 200) {
            transaction = resp.data.data.transactions;

            let findTx = transaction.find(
              (element) => element.type === typeTransaction
            );
            if (findTx !== undefined) {
              setTxDetail(findTx);
              //  setDeclinedReason("");
            }
            setLoadingTx(false);
          }
        }
      } catch (error) {
        //  console.log(error);
        // setDeclinedReason("");
        setLoadingTx(false);
        props.currentToken(error?.response?.headers["x-new-access-token"]);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 422
        ) {
          message.error("An error has ocurred. Please sign in again");
          window.localStorage.setItem("success", false);
          history.push("/signin");
        }
      }
    }
  }
  async function getUsers() {
    try {
      setLoading(true);
      let lastID = null;
      if (lastIdUsers === null) {
        lastID = undefined;
      } else {
        lastID = lastIdUsers;
      }
      dispatch(usersDamex(token.clientId, token.current, lastID));
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.error_details.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
        message.error("Invalid token");
      } else if (error.response.status === 422) {
        message.error("Invalid token");
      } else if (error.response.status === 401) {
        message.error("Expired token");
      } else if (error.response.status === 403 && error.response.status === 500)
        message.error(error.response.data.message);
    }
  }
  async function formatData() {
    const response = usersListResp;
    let lastID = null;
    if (lastIdUsers === null) {
      lastID = undefined;
    } else {
      lastID = lastIdUsers;
    }
    if (response.status === 200) {
      let data = response.data.data;
      let users = response.data.data.users;
      if (lastID !== undefined && lastID === data.previous_id) {
        users = [...usersList, ...users];
      }
      let orderedList = users.sort(function (a, b) {
        let fullNameA = "";
        let fullNameB = "";

        fullNameA = a.name;

        fullNameB = b.name;

        return fullNameA.localeCompare(fullNameB);
      });
      if (data.current_page_size < data.max_elements_per_page) {
        setShowButtonLoad(false);
      } else {
        setShowButtonLoad(true);
      }
      setLastIdUsers(
        data.current_last_id !== null ? data.current_last_id : null
      );
      setUsersList(orderedList);
      setLoading(false);
    }
  }
  function validationError(messageError, otherMessage) {
    if (messageError === "INVALID_EMAIL_FORMAT") {
      message.error("'Email' is invalid", 10);
    } else if (messageError === "INVALID_LAST_USER") {
      message.error("Last_id is invalid", 10);
    } else {
      message.error(otherMessage, 10);
    }
  }
  function handleUser(value) {
    if (value) {
      setCurrentUser(value.split("/")[2]);
      let val = value.split("/")[0];
      let email;
      val !== "" ? (email = val) : (email = "");
      setFilter({
        ...filter,
        user_email: email,
      });
    } else {
      setCurrentUser(null);
      setFilter({
        ...filter,
        user_email: "",
      });
    }
  }
  const LABEL_TRANSLATIONS = {
    _id: "Internal ID",
    currency: "Currency",
    country: "Country",
    customer_name: "User name",
    type: "Type",
    entity: "Entity",
    exchange_lp_rate: "LP rate",
    exchange_user_rate: "User rate",
    lp_executed_price: "LP executed price",
    slippage_percentage: "Slippage Percentage",
    rate_vs_eur: "Rate vs EUR",
    rate_vs_gbp: "Rate vs GBP",
    total_fee_eur: "Total fee in EUR",
    total_fee_gbp: "Total fee in GBP",
    vault_account_id: "Vault account ID",
    fiat_customer_id: "FIAT account ID",
    client_percentage_fee: "Client percentage fees",
    only_fiat_accounts: "Only FIAT accounts",
    user_id: "User ID",
    TRANSFER_IN: "Transfer in",
    TRANSFER_OUT: "Transfer out",
    EXCHANGE_IN: "Exchange in",
    EXCHANGE_OUT: "Exchange out",
    counterparty_details: "Counterparty details",
    transaction_hash: "Transaction hash",
    amount: "Amount",
    fee: "Fee",
    AML_HOLD: "AML Hold",
    AML_FREEZE: "AML Freeze",
    aml_rules: "AML Rules",
    aml_logs: "AML Logs",
    declined_reason: "Decline reason",
    cancelled_by: "Cancelled by",
    cancelled_by_id: "Cancelled by (ID)",
  };

  function labelName(name) {
    return LABEL_TRANSLATIONS[name] || name.replaceAll("_", " ");
  }

  function valueFormat(name) {
    let value = {};
    if (name.includes("percentage")) {
      value.symbol = "%";
      value.type = "number";
    } else if (name.includes("eur")) {
      value.symbol = "EUR";
      value.type = "number";
    } else if (name.includes("gbp")) {
      value.symbol = "GBP";
      value.type = "number";
    } else if (name === "fee") {
      value.symbol = "currency";
      value.type = "number";
    } else if (name === "amount") {
      value.symbol = "currency";
      value.type = "number";
    } else if (name.includes("rate") || name.includes("price")) {
      value.symbol = "currency";
      value.type = "number";
    } else if (name === "aml_rules") {
      value.type = "array";
      value.symbol = "";
    } else if (name === "aml_logs") {
      value.type = "array";
      value.symbol = "";
    } else {
      value.symbol = "";
      value.type = "text";
    }
    return value;
  }
  function showUrl(record) {
    let tx = record.transaction_hash;
    let url = "";
    if (record.currency === "BTC") {
      url = URL_BTC + tx;
    } else if (record.currency === "ETH") {
      url = URL_ETH + tx;
    } else {
      if (record.network === "ERC20") {
        url = URL_ETH + tx;
      } else if (record.network === "TRC20") {
        url = URL_TRON + tx;
      }
    }
    return url;
  }
  function formatAmlRules(rules) {
    //  console.log(rules);
    let formatedRulesString = [];
    for (let rule of rules) {
      if (formatedRulesString.length === 0) {
        if (rules.length > 1) {
          formatedRulesString.push(
            <div>{rule.rule_id + " (" + rule.severity + "),"}</div>
          );
        } else {
          formatedRulesString.push(
            <div>{rule.rule_id + " (" + rule.severity + ")"}</div>
          );
        }
      } else {
        if (formatedRulesString.length + 1 === rules.length) {
          formatedRulesString.push(
            <div>{rule.rule_id + " (" + rule.severity + ")"}</div>
          );
        } else {
          formatedRulesString.push(
            <div>{rule.rule_id + " (" + rule.severity + "),"}</div>
          );
        }
      }
    }
    // console.log(formatedRulesString);
    return formatedRulesString;
  }
  function formatAmlLogs(logs) {
    let formatedRulesString = [];

    for (let amlLog of logs) {
      formatedRulesString.push(
        <div>
          This transaction was {amlLog.status} by {amlLog.email} on the{" "}
          {amlLog.decision_date}.{" "}
        </div>
      );
    }

    return formatedRulesString;
  }
  function defineDeclinedReason(record) {
    const { aml_logs } = record;
    if (aml_logs) {
      let declinedReasonColection = [];
      for (let amlLog of aml_logs) {
        if (amlLog.rejection_reason) {
          declinedReasonColection.push(amlLog.rejection_reason);
        }
      }
      if (declinedReasonColection.length > 0) {
        return declinedReasonColection[0];
      }
    }
    return null;
  }
  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
      title={
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#fff",
            textAlign: "left",
          }}
        >
          {props.source + " transactions"}
        </Text>
      }
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Currency"
            name="currency_code"
            loading={currenciesList.length === 0}
            onChange={(value) => {
              let code;
              value !== "ALL" ? (code = value) : (code = null);
              setFilter({
                ...filter,
                currency_code: code,
              });
            }}
          >
            {currenciesList.map((item, i) => {
              return (
                <Option key={i} value={item.code}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DatePicker
            placeholder="Start date"
            size="large"
            allowClear
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            showTime={false}
            showToday={false}
            name="min_date"
            onChange={(value) => {
              const date = value ? moment(value).format("YYYY-MM-DD") : "";
              const time = value ? new Date().getTimezoneOffset() : "";
              setFilter({
                ...filter,
                min_date: date,
                timezone_offset: time,
              });
            }}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DatePicker
            placeholder="End date"
            size="large"
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            allowClear
            name="max_date"
            onChange={(value) => {
              const date = value ? moment(value).format("YYYY-MM-DD") : "";
              const time = value ? new Date().getTimezoneOffset() : "";
              setFilter({
                ...filter,
                max_date: date,
                timezone_offset: time,
              });
            }}
            showTime={false}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="User Email"
            name="user_email"
            onChange={handleInput}
          ></Input>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          {props.source === "Damex Direct" && (
            <Select
              allowClear
              showSearch
              defaultValue={currentUser}
              filterOption={(input, option) => {
                setCurrentUser(input);
                if (option.value) {
                  let value = option.value.split("/")[2];
                  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              }}
              style={{ width: "100%" }}
              size="large"
              placeholder="Client Name"
              loading={loading}
              disabled={loading}
              name="client_name"
              onChange={(value) => handleUser(value)}
              value={currentUser}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {showButtonLoad && (
                    <div style={{ background: "#00001E" }}>
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }} align="center">
                        <Button type="link" onClick={() => getUsers()}>
                          Load more
                        </Button>
                      </Space>
                    </div>
                  )}
                </>
              )}
            >
              {usersList.map((item, i) => {
                return (
                  <Option
                    value={item.email + "/" + item._id + "/" + item.name}
                    key={item._id}
                  >
                    {item.name + " - " + item.email}
                  </Option>
                );
              })}
            </Select>
          )}
          {props.source !== "Damex Direct" && (
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              size="large"
              placeholder="Status"
              onChange={(value) => {
                let stat;
                value !== "ALL" ? (stat = value) : (stat = "");
                setFilter({
                  ...filter,
                  status: stat,
                });
              }}
            >
              {status.map((item, i) => {
                return (
                  <Option key={i} value={item.key}>
                    {item.value}
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="Transaction hash"
            name="transaction_hash"
            onChange={handleInput}
          ></Input>
        </Col>
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="User ID"
              name="user_id"
              onChange={handleInput}
            ></Input>
          </Col>
        )}
      </Row>
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        {(props.source === "Damex" || props.source === "Global") && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="User ID"
              name="user_id"
              onChange={handleInput}
            ></Input>
          </Col>
        )}
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="Tx ID"
            name="transaction_id"
            onChange={handleInput}
          ></Input>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Entity"
            name="entity"
            onChange={(value) => {
              let entity;
              value !== "ALL" ? (entity = value) : (entity = "");
              setFilter({
                ...filter,
                entity: entity,
              });
            }}
          >
            {entityList.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Type"
            name="type"
            onChange={(value) => {
              let ty;
              value !== "ALL" ? (ty = value) : (ty = "");
              setFilter({
                ...filter,
                type: ty,
              });
            }}
          >
            {transactionTypeList.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>
        {props.source === "Damex Direct" && (
          <>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                size="large"
                placeholder="Status"
                onChange={(value) => {
                  let stat;
                  value !== "ALL" ? (stat = value) : (stat = "");
                  setFilter({
                    ...filter,
                    status: stat,
                  });
                }}
              >
                {status.map((item, i) => {
                  return (
                    <Option key={i} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                size="large"
                placeholder="AML Screened"
                name="aml_screened"
                onChange={(value) => {
                  let screened;
                  value !== "ALL" ? (screened = value) : (screened = "");
                  setFilter({
                    ...filter,
                    aml_screened: screened,
                  });
                }}
              >
                {amlScreenedList.map((item, i) => {
                  return (
                    <Option key={i} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                size="large"
                placeholder="AML Reviewer"
                name="aml_reviewer"
                loading={loadingReviewer}
                onChange={(value) => {
                  let reviewer;
                  value !== "ALL" ? (reviewer = value) : (reviewer = "");
                  setFilter({
                    ...filter,
                    aml_reviewer_id: reviewer,
                  });
                }}
              >
                {amlReviewer.map((item, i) => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {item.email}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              xxl={{ span: props.source === "Damex Direct" ? 3 : 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                size="large"
                placeholder="AML trigger"
                name="aml_trigger"
                loading={loadingRules}
                onChange={(value) => {
                  let trigger;
                  value !== "ALL" ? (trigger = value) : (trigger = "");
                  setFilter({
                    ...filter,
                    aml_rule_id: trigger,
                  });
                }}
              >
                {rulesAmlList.map((item, i) => {
                  return (
                    <Option key={item._id} value={item.rule_id}>
                      {item.rule_id}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </>
        )}
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex" ? 4 : 3, offset: 0 }}
          xl={{ span: props.source === "Damex" ? 4 : 3, offset: 0 }}
          xxl={{ span: props.source === "Damex" ? 4 : 3, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            type="primary"
            size="large"
            loading={isFiltering}
            style={{ width: "100%" }}
            onClick={() => getTransactionsList(true)}
          >
            Filter
          </Button>
        </Col>
      </Row>
      {/* {dataSource !== null && ( */}
      <>
        <div
          id="table-history"
          className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
          style={{ marginTop: 10 }}
        >
          {!flag && !isFiltering && dataSource !== null && (
            <Table
              rowKey="_id"
              locale={{
                emptyText: (
                  <Space direction="vertical">
                    <br />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Hmm, we didn't find any records
                    </Text>
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "normal",
                      }}
                    >
                      Please check your filters and try again
                    </Text>
                    <br />
                  </Space>
                ),
              }}
              dataSource={dataSource}
              scroll={{ x: dataSource?.length === 0 ? 1300 : 1800 }}
              columns={columns}
              onChange={handleTableChange}
              pagination={pagination}
              rowClassName={(record, index) =>
                index === rowSelected && expandedRow
                  ? "row-selected"
                  : "row-table-deposit"
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: async (event) => {
                    // setDeclinedReason("");
                    setRowSelected(rowIndex);
                    if (record._id === rowId) {
                      setRowId("");
                    } else {
                      setRowId(record._id);
                    }
                    await getTxDetail(rowIndex, record);
                  },
                };
              }}
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => {
                  return expanded ? (
                    <DownOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={(e) => onExpand(record, e)} />
                  );
                },
                expandedRowRender: (record, index) => {
                  return (
                    <>
                      <Row
                        gutter={4}
                        style={{ marginLeft: 60, marginRight: 60 }}
                      >
                        {defineDeclinedReason(record) !== null && (
                          <>
                            <Col className="gutter-row" span={3}>
                              <Text
                                style={{
                                  color: "#fff",
                                  fontSize: 16,
                                }}
                              >
                                {labelName("declined_reason")}:
                              </Text>
                            </Col>
                            <Col className="gutter-row" span={5}>
                              <Text
                                style={{
                                  color: "#ccc",
                                  fontSize: 16,
                                }}
                              >
                                {defineDeclinedReason(record)}
                              </Text>
                            </Col>
                          </>
                        )}

                        {record.aml_rules &&
                          formatAmlRules(record.aml_rules).length > 0 && (
                            <>
                              <Col className="gutter-row" span={3}>
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 16,
                                  }}
                                >
                                  {labelName("aml_logs")}:
                                </Text>
                              </Col>
                              <Col className="gutter-row" span={5}>
                                <Text
                                  style={{
                                    color: "#ccc",
                                    fontSize: 16,
                                  }}
                                >
                                  {formatAmlLogs(record.aml_logs).map(
                                    (data) => {
                                      return data;
                                    }
                                  )}
                                </Text>
                              </Col>
                            </>
                          )}
                        {record.aml_rules &&
                          formatAmlRules(record.aml_rules).length > 0 && (
                            <>
                              <Col className="gutter-row" span={3}>
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 16,
                                  }}
                                >
                                  {labelName("aml_rules")}:
                                </Text>
                              </Col>
                              <Col className="gutter-row" span={5}>
                                <Text
                                  style={{
                                    color: "#ccc",
                                    fontSize: 16,
                                  }}
                                >
                                  {formatAmlRules(record.aml_rules).map(
                                    (data) => {
                                      return data;
                                    }
                                  )}
                                </Text>
                              </Col>
                            </>
                          )}

                        {Object.entries(record).map(([key, value], i) => {
                          if (
                            value != null &&
                            key !== "key" &&
                            key !== "_id" &&
                            key !== "entity" &&
                            key !== "type" &&
                            key !== "transaction_id" &&
                            key !== "platform" &&
                            key !== "amount" &&
                            key !== "status" &&
                            key !== "user_email" &&
                            key !== "fee" &&
                            key !== "transaction_date" &&
                            key !== "spread_percentage" &&
                            key !== "spread_fee" &&
                            key !== "customer_name" &&
                            key !== "user_id" &&
                            key !== "currency" &&
                            key !== "country" &&
                            key !== "network" &&
                            key !== "transaction_hash" &&
                            key !== "aml_severity" &&
                            key !== "counterparty_details" &&
                            key !== "aml_rules" &&
                            key !== "aml_logs"
                          ) {
                            return (
                              <>
                                <Col className="gutter-row" span={3}>
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 16,
                                    }}
                                  >
                                    {labelName(key)}:
                                  </Text>
                                </Col>
                                <Col className="gutter-row" span={5}>
                                  {valueFormat(key).type === "number" && (
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType="text"
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#ccc",
                                      }}
                                      suffix={
                                        valueFormat(key).symbol === "currency"
                                          ? " " + record.currency
                                          : " " + valueFormat(key).symbol
                                      }
                                      decimalScale={
                                        valueFormat(key).symbol === "GBP" ||
                                        valueFormat(key).symbol === "EUR"
                                          ? 2
                                          : 8
                                      }
                                      value={value}
                                    />
                                  )}
                                  {valueFormat(key).type === "text" && (
                                    <Text
                                      style={{
                                        color: "#ccc",
                                        fontSize: 16,
                                      }}
                                    >
                                      {value === true && "Sí"}
                                      {value === false && "No"}
                                      {value !== true &&
                                        value !== false &&
                                        value !== "" &&
                                        (value.includes("EXCHANGE")
                                          ? labelName(value)
                                          : value)}
                                    </Text>
                                  )}
                                </Col>
                              </>
                            );
                          }
                          return null;
                        })}

                        {record.transaction_hash !== null &&
                          record.transaction_hash !== "" && (
                            <>
                              <Col className="gutter-row" span={3}>
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 16,
                                  }}
                                >
                                  {labelName("transaction_hash")}:
                                </Text>
                              </Col>
                              <Col className="gutter-row" span={5}>
                                <Link
                                  style={{ color: "#53ff84" }}
                                  href={showUrl(record)}
                                  target="_blank"
                                >
                                  {record.transaction_hash}
                                </Link>
                              </Col>
                            </>
                          )}
                      </Row>
                      {(record.type === "EXCHANGE_IN" ||
                        record.type === "EXCHANGE_OUT") && (
                        <>
                          <Divider
                            style={{ borderTopColor: "#fff" }}
                            orientation="left"
                          >
                            <Text
                              style={{
                                color: "#fff",
                                fontSize: 16,
                              }}
                            >
                              {record.type === "EXCHANGE_OUT"
                                ? "Linked Exchange In"
                                : "Linked Exchange Out"}
                            </Text>
                          </Divider>
                          {!loadingTx && Object.keys(txDetail).length !== 0 && (
                            <>
                              <Row
                                gutter={4}
                                style={{ marginLeft: 60, marginRight: 60 }}
                              >
                                {Object.entries(txDetail).map(
                                  ([key, value], i) => {
                                    return (
                                      <>
                                        {value != null &&
                                          key !== "transaction_id" &&
                                          key !== "platform" &&
                                          key !== "status" &&
                                          key !== "user_email" &&
                                          key !== "transaction_date" &&
                                          key !== "spread_percentage" &&
                                          key !== "spread_fee" &&
                                          key !== "vault_account_id" &&
                                          key !== "only_fiat_accounts" &&
                                          key !== "customer_name" &&
                                          key !== "user_id" &&
                                          key !== "country" &&
                                          key !== "fiat_customer_id" &&
                                          key !== "entity" &&
                                          key !== "network" &&
                                          key !== "transaction_hash" &&
                                          key !== "aml_severity" &&
                                          key !== "aml_rules" &&
                                          key !== "aml_logs" &&
                                          key !== "counterparty_details" && (
                                            <>
                                              <Col
                                                className="gutter-row"
                                                span={3}
                                                key={i}
                                              >
                                                <Text
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: 16,
                                                  }}
                                                >
                                                  {labelName(key)}:
                                                </Text>
                                              </Col>
                                              <Col
                                                className="gutter-row"
                                                span={5}
                                              >
                                                {valueFormat(key).type ===
                                                  "number" && (
                                                  <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    style={{
                                                      fontSize: 16,
                                                      fontWeight: 400,
                                                      color: "#ccc",
                                                    }}
                                                    suffix={
                                                      valueFormat(key)
                                                        .symbol === "currency"
                                                        ? " " +
                                                          txDetail.currency
                                                        : " " +
                                                          valueFormat(key)
                                                            .symbol
                                                    }
                                                    decimalScale={
                                                      valueFormat(key)
                                                        .symbol === "GBP" ||
                                                      valueFormat(key)
                                                        .symbol === "EUR"
                                                        ? 2
                                                        : 8
                                                    }
                                                    value={value}
                                                  />
                                                )}
                                                {valueFormat(key).type ===
                                                  "text" && (
                                                  <Text
                                                    style={{
                                                      color: "#ccc",
                                                      fontSize: 16,
                                                    }}
                                                  >
                                                    {value === true && "Si"}
                                                    {value === false && "No"}
                                                    {value !== true &&
                                                      value !== false &&
                                                      value !== "" &&
                                                      (value.includes(
                                                        "EXCHANGE"
                                                      )
                                                        ? labelName(value)
                                                        : value)}
                                                  </Text>
                                                )}
                                              </Col>
                                            </>
                                          )}
                                      </>
                                    );
                                  }
                                )}
                              </Row>
                            </>
                          )}
                        </>
                      )}
                      {record.transaction_id !== null &&
                        Object.keys(txDetail).length > 0 && (
                          <>
                            {(record.type === "TRANSFER_IN" ||
                              record.type === "TRANSFER_OUT") && (
                              <>
                                <Divider
                                  style={{ borderTopColor: "#fff" }}
                                  orientation="left"
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 16,
                                    }}
                                  >
                                    {record.type === "TRANSFER_OUT"
                                      ? "Linked Transfer In"
                                      : "Linked Transfer Out"}
                                  </Text>
                                </Divider>
                                {!loadingTx &&
                                  Object.keys(txDetail).length !== 0 && (
                                    <>
                                      <Row
                                        gutter={4}
                                        style={{
                                          marginLeft: 60,
                                          marginRight: 60,
                                        }}
                                      >
                                        {Object.entries(txDetail).map(
                                          ([key, value], i) => {
                                            return (
                                              <>
                                                {value != null &&
                                                  key !== "currency" &&
                                                  key !== "transaction_id" &&
                                                  key !== "platform" &&
                                                  key !== "amount" &&
                                                  key !== "status" &&
                                                  key !== "user_email" &&
                                                  key !== "fee" &&
                                                  key !== "transaction_date" &&
                                                  key !== "spread_percentage" &&
                                                  key !== "spread_fee" &&
                                                  key !== "rate_vs_eur" &&
                                                  key !== "rate_vs_gbp" &&
                                                  key !== "total_fee_eur" &&
                                                  key !== "total_fee_gbp" &&
                                                  key !== "network" &&
                                                  key !== "transaction_hash" &&
                                                  key !== "aml_severity" &&
                                                  key !== "aml_rules" &&
                                                  key !== "aml_logs" &&
                                                  key !==
                                                    "counterparty_details" && (
                                                    <>
                                                      <Col
                                                        className="gutter-row"
                                                        span={3}
                                                      >
                                                        <Text
                                                          style={{
                                                            color: "#fff",
                                                            fontSize: 16,
                                                          }}
                                                        >
                                                          {labelName(key)}:
                                                        </Text>
                                                      </Col>
                                                      <Col
                                                        className="gutter-row"
                                                        span={5}
                                                      >
                                                        {valueFormat(key)
                                                          .type ===
                                                          "number" && (
                                                          <NumberFormat
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            displayType="text"
                                                            style={{
                                                              fontSize: 16,
                                                              fontWeight: 400,
                                                              color: "#ccc",
                                                            }}
                                                            suffix={
                                                              valueFormat(key)
                                                                .symbol ===
                                                              "currency"
                                                                ? " " +
                                                                  txDetail.currency
                                                                : " " +
                                                                  valueFormat(
                                                                    key
                                                                  ).symbol
                                                            }
                                                            decimalScale={
                                                              valueFormat(key)
                                                                .symbol ===
                                                                "GBP" ||
                                                              valueFormat(key)
                                                                .symbol ===
                                                                "EUR"
                                                                ? 2
                                                                : 8
                                                            }
                                                            value={value}
                                                          />
                                                        )}
                                                        {valueFormat(key)
                                                          .type === "text" && (
                                                          <Text
                                                            style={{
                                                              color: "#ccc",
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {value === true &&
                                                              "Si"}
                                                            {value === false &&
                                                              "No"}
                                                            {value !== true &&
                                                              value !== false &&
                                                              value !== "" &&
                                                              (value.includes(
                                                                "TRANSFER"
                                                              )
                                                                ? labelName(
                                                                    value
                                                                  )
                                                                : value)}
                                                          </Text>
                                                        )}
                                                      </Col>
                                                    </>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      {loadingTx &&
                        (record.type.includes("EXCHANGE") ||
                          record.type.includes("TRANSFER")) && (
                          <Skeleton active />
                        )}
                    </>
                  );
                },
                expandedRowKeys: [rowId],
                onExpand: async (expanded, record) => {
                  await setExpandedRow(expanded);
                  // getTxDetail(expanded, record);
                },
              }}
            />
          )}{" "}
        </div>
      </>
      {/* )} */}
      {(flag || isFiltering || dataSource === null) && <Skeleton active />}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionResult: state.transaction.transactionResponse,
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    supportedCurrencies: state.otc.currencies,
  };
};

const mapDispatchToProps = {
  getTransactions,
  currentToken,
  clearTransactions,
  clearDataSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable);

import React, { useEffect, useState } from "react";
import "./createTransactionOTC";
import { Row, Col, Button, Form, Typography, Space, Select } from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
import { RightOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;
const OtcBridge = (props) => {
  const [networks, setNetworks] = useState([]);
  useEffect(() => {
    getNetworks(props.accountData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getNetworks(data) {
    let account = data.account_data;
    let array = [];
    if (account.networks !== undefined) {
      array = account.networks;
      let findAddress = array.find(
        (element) =>
          element.network === "BASE_ASSET" && element.own_address === false
      );
      if (findAddress !== undefined) {
        let arrayAddress = [];
        props.handleNetwork(findAddress.network);
        arrayAddress.push(findAddress);
        setNetworks(arrayAddress);
      } else {
        props.handleNetwork(array[0].network);
        setNetworks(array);
      }
    }
  }
  function getInfoCurrency(currency) {
    let data = asset.assets;
    let findCurrency = data.find((element) => element.value === currency);
    return findCurrency;
  }
  function fieldsValidator(rule, value, callback) {
    let accountBalance = props.accountData.available_balance;
    let amount = parseFloat(props.amount);
    if (value !== "") {
      if (amount > accountBalance) {
        return Promise.reject(
          new Error("Amount cannot be greater than the available balance")
        );
      }

      return Promise.resolve();
    }
  }
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Col
        className="gutter-row"
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
      >
        <Row justify="start" style={{ marginBottom: 10 }}>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            OTC Bridge
          </Text>
        </Row>
        <Row justify="start">
          <Text
            style={{
              color: "#ccc",
              fontSize: 14,
              textAlign: "left",
            }}
          >
            Select the amount to withdraw from {props.userData.split("_")[1]}{" "}
            balance
          </Text>
        </Row>
        <br />
        <Row justify="start">
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 5,
            }}
          >
            WITHDRAWABLE BALANCE
          </Text>
        </Row>
        <Row justify="start">
          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            xl={{ span: 10, offset: 0 }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
                textAlign: "left",
                fontWeight: "bold",
                marginBottom: 5,
              }}
            >
              {props.userData.split("_")[1]}
            </Text>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 14, offset: 0 }}
            lg={{ span: 14, offset: 0 }}
            xl={{ span: 14, offset: 0 }}
          >
            <Text
              style={{
                color: "#ccc",
                fontSize: 18,
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                prefix={
                  getInfoCurrency(props.accountData.currency).symbol + " "
                }
                decimalScale={
                  getInfoCurrency(props.accountData.currency).type === "FIAT"
                    ? 2
                    : 8
                }
                value={props.accountData.available_balance}
              />
            </Text>
          </Col>
        </Row>
        <br />
        <Row justify="start" style={{ marginBottom: 15 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Form
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="balance"
              autoComplete="off"
            >
              {networks.length > 1 && (
                <Form.Item
                  style={{ width: "100%" }}
                  label={<Text className="label-dark">Network</Text>}
                  name="network"
                >
                  <div
                    id="select-dark"
                    className="ant-select-selector ant-select"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      allowClear
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: "#fff",
                          }}
                        />
                      }
                      style={{
                        color: "#fff",
                        background: "#00001E",
                      }}
                      size="large"
                      disabled={false}
                      value={props.network}
                      onChange={(value) => props.handleNetwork(value)}
                    >
                      {networks.map((item, i) => {
                        return (
                          <Option key={item.asset_id} value={item.network}>
                            {item.network}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              )}
              <Form.Item
                style={{ width: "100%" }}
                label={<Text className="label-dark">Enter Amount</Text>}
                name="amount"
                rules={[{ validator: fieldsValidator }]}
              >
                <NumberFormat
                  allowNegative={false}
                  thousandSeparator={true}
                  prefix={
                    getInfoCurrency(props.accountData.currency).symbol + " "
                  }
                  className="input-amount"
                  placeholder={
                    getInfoCurrency(props.accountData.currency).symbol + " 0.00"
                  }
                  decimalScale={
                    getInfoCurrency(props.accountData.currency).type === "FIAT"
                      ? 2
                      : 8
                  }
                  onValueChange={(values) => props.handleAmount(values)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>

      <Row justify="center">
        <Button
          type="primary"
          size="large"
          style={{ width: "50%", fontWeight: "bold" }}
          onClick={() => props.createTransactionOtc()}
          loading={props.loading}
          disabled={
            props.amount === "" ||
            parseFloat(props.amount) >
              parseFloat(props.accountData.available_balance)
          }
        >
          Send to OTC
        </Button>
      </Row>
    </Space>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OtcBridge);

import React from "react";
import { Tabs, Typography, Divider } from "antd";
import "./crypto.less";
import WithdrawalVaultsTable from "./withdrawalVaultsTable";
import OmnibusTable from "./omnibusTable";
const { Text } = Typography;
const Crypto = () => {
  const itemsTab = [
    // {
    //   key: "1",
    //   label: (
    //     <Text
    //       style={{
    //         fontSize: 18,
    //         color: "#fff",
    //       }}
    //     >
    //       Global
    //     </Text>
    //   ),
    //   children: (
    //     <>
    //       <Divider
    //         orientation="left"
    //         style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
    //       >
    //         Withdrawal Vaults
    //       </Divider>
    //       <WithdrawalVaultsTable />
    //       <br />
    //       <Divider
    //         orientation="left"
    //         style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
    //       >
    //         Omnibus (Deposits) Vaults
    //       </Divider>
    //       <OmnibusTable />
    //     </>
    //   ),
    // },
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: (
        <>
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Withdrawal Vaults
          </Divider>
          <WithdrawalVaultsTable />
          <br />
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Omnibus (Deposits) Vaults
          </Divider>
          <OmnibusTable />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: (
        <>
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Withdrawal Vaults
          </Divider>
          <WithdrawalVaultsTable />
          <br />
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Omnibus (Deposits) Vaults
          </Divider>
          <OmnibusTable />
        </>
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={itemsTab}></Tabs>;
};
export default Crypto;

import React from "react";
import { Tag } from "antd";

const statusStyles = {
  COMPLETED: {
    color: "#389E0D",
    background: "#F6FFED",
    borderColor: "#B7EB8F",
  },
  PROCESSING: {
    color: "#08979C",
    background: "#E6FFFB",
    borderColor: "#87E8DE",
  },
  INITIATED: {
    color: "#096DD9",
    background: "#E6F7FF",
    borderColor: "#91D5FF",
  },
  PENDING: {
    color: "#531DAB",
    background: "#F9F0FF",
    borderColor: "#D3ADF7",
  },
  REJECTED: {
    color: "#D4380D",
    background: "#FFF2E8",
    borderColor: "#FFBB96",
  },
  FAILED: {
    color: "#CF1322",
    background: "#FFF1F0",
    borderColor: "#FFA39E",
  },
  AML_HOLD: {
    color: "#D48806",
    background: "#FFFBE6",
    borderColor: "#FFE58F",
  },
  AML_FREEZE: {
    color: "#0958D9",
    background: "#E6F4FF",
    borderColor: "#91CAFF",
  },
  CLIENT_APPROVAL_PENDING: {
    color: "#D48806",
    background: "#FFFBE6",
    borderColor: "#FFE58F",
  },
  APPROVAL_EXPIRED: {
    color: "#C41D7F",
    background: "#FFF0F6",
    borderColor: "#FFB6D9",
  },
  PENDING_SETTLEMENT: {
    color: "#597ef7",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
  },
};

const statusText = {
  COMPLETED: "COMPLETED",
  PROCESSING: "PROCESSING",
  INITIATED: "INITIATED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  FAILED: "FAILED",
  AML_HOLD: "AML HOLD",
  AML_FREEZE: "AML FREEZE",
  CLIENT_APPROVAL_PENDING: "APPROVAL PENDING",
  APPROVAL_EXPIRED: "APPROVAL EXPIRED",
  PENDING_SETTLEMENT: "PENDING SETTLEMENT",
};

const StatusTag = ({ status }) => {
  const style = statusStyles[status] || {};
  const displayText = statusText[status] || status.replaceAll("_", " ");

  return (
    <Tag
      style={{
        fontSize: 12,
        ...style,
      }}
    >
      {displayText}
    </Tag>
  );
};

export default StatusTag;

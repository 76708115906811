// Dependencies
import { useState } from "react";
import moment from "moment";

export const useFiltersState = () => {
  // Main filter state for API requests
  const [filter, setFilter] = useState({});
  
  // State to track filtering process
  const [isFiltering, setIsFiltering] = useState(false);
  
  // Date range states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  // Text input fields state
  const [inputValues, setInputValues] = useState({
    transaction_id: "",
    user_email: "",
    user_fullname: "",
  });
  
  // Select fields state
  const [selectValues, setSelectValues] = useState({
    entity: undefined,
    currency_code: undefined,
    type: undefined,
  });

  // Handler for select field changes
  const handleFilterChange = (value, field) => {
    setSelectValues((prev) => ({ ...prev, [field]: value }));
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (value === "ALL" || !value) {
        delete newFilter[field];
      } else {
        newFilter[field] = value;
      }
      return newFilter;
    });
  };

  // Handler for text input changes
  const handleInputChange = (e, field) => {
    const value = e.target.value.trim();
    setInputValues((prev) => ({ ...prev, [field]: value }));
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (!value) {
        delete newFilter[field];
      } else {
        newFilter[field] = value;
      }
      return newFilter;
    });
  };

  // Handler for date picker changes
  const handleDateChange = (type, value) => {
    const date = value ? moment(value).format("YYYY-MM-DD") : "";
    if (type === "start") {
      setStartDate(value);
      setFilter((prev) => ({
        ...prev,
        min_date: date,
      }));
    } else {
      setEndDate(value);
      setFilter((prev) => ({
        ...prev,
        max_date: date,
      }));
    }
  };

  // Handler for resetting all filters
  const handleReset = async (handleSearch) => {
    // Reset text input values
    setInputValues({
      transaction_id: "",
      user_email: "",
      user_fullname: "",
    });

    // Reset select values
    setSelectValues({
      entity: undefined,
      currency_code: undefined,
      type: undefined,
    });

    // Reset date range
    setStartDate(null);
    setEndDate(null);
    
    // Reset main filter
    setFilter({});

    // Trigger search with empty filters
    try {
      await handleSearch({});
    } catch (error) {}
  };

  return {
    filter,
    isFiltering,
    setIsFiltering,
    startDate,
    endDate,
    inputValues,
    selectValues,
    handleFilterChange,
    handleInputChange,
    handleDateChange,
    handleReset,
  };
};

import React from "react";
import { Tabs, Typography } from "antd";
import "./users.less";
import UsersTable from "./usersTable";
const { Text } = Typography;

const Users = () => {
  const itemsTab = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <UsersTable source={"Damex Direct"} />,
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: <UsersTable source={"Damex"} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      items={itemsTab}
      destroyInactiveTabPane={true}
    />
  );
};
export default Users;

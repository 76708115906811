import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

const ExpandedRow = ({ record, fields }) => (
  <Row gutter={[16, 16]} style={{ padding: "20px" }}>
    {fields.map((field) => (
      <Col span={12} key={field.key}>
        <Row>
          <Col span={8}>
            <Text style={{ color: "#fff", fontSize: 16 }}>
              {field.label}:
            </Text>
          </Col>
          <Col span={16}>
            <Text style={{ color: "#ccc", fontSize: 16 }}>
              {record[field.key] || "-"}
            </Text>
          </Col>
        </Row>
      </Col>
    ))}
  </Row>
);

export default ExpandedRow; 
// Dependencies
import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

// Services
import pendingSettlements from "../../../services/pendingSettlements";

export const useHighValueBalance = () => {
  // State for loading status and balance data
  const [loading, setLoading] = useState(true);
  const [tradingAccounts, setTradingAccounts] = useState([]);
  const [withdrawalVault, setWithdrawalVault] = useState([]);

  // Redux state selectors for authentication
  const { token, clientId } = useSelector((state) => ({
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  }));

  // Handler for fetching balance data
  const fetchBalances = async () => {
    try {
      setLoading(true);
      const response = await pendingSettlements.getBalance(clientId, token);
      const { high_value_trading_accounts, high_value_withdrawal_vault } =
        response.data.data;

      const groupedTradingAccounts = high_value_trading_accounts.reduce(
        (acc, balance) => {
          if (!acc[balance.entity]) {
            acc[balance.entity] = [];
          }
          acc[balance.entity].push(balance);
          return acc;
        },
        {}
      );

      const formattedTradingAccounts = Object.entries(
        groupedTradingAccounts
      ).map(([entity, balances]) => ({
        entity,
        balances,
      }));

      setTradingAccounts(formattedTradingAccounts);
      setWithdrawalVault(high_value_withdrawal_vault);
    } catch (error) {
      if (error.response?.data) {
        message.error(error.response.data.message);
      } else {
        message.error("Error fetching balances");
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch balances on component mount
  useEffect(() => {
    fetchBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    tradingAccounts,
    withdrawalVault,
    refreshBalances: fetchBalances,
  };
};

import React from "react";
import { Tabs, Typography, Divider } from "antd";
import "./fiat.less";
import ExchangeTable from "./exchangeTreasuriesTable";
import FeesTable from "./feesTable";
const { Text } = Typography;
const Fiat = () => {
  const tabItems = [
    // {
    //   key: "1",
    //   label: (
    //     <Text
    //       style={{
    //         fontSize: 18,
    //         color: "#fff",
    //       }}
    //     >
    //       Global
    //     </Text>
    //   ),
    //   children: (
    //     <>
    //       <Divider
    //         orientation="left"
    //         style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
    //       >
    //         Exchange Treasuries Accounts
    //       </Divider>
    //       <ExchangeTable source={"Global"} />
    //       <br />
    //       <Divider
    //         orientation="left"
    //         style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
    //       >
    //         Fee's Accounts
    //       </Divider>
    //       <FeesTable source={"Global"} />
    //     </>
    //   ),
    // },
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: (
        <>
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Exchange Treasuries Accounts
          </Divider>
          <ExchangeTable source={"Damex Direct"} />
          <br />
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Fee's Accounts
          </Divider>
          <FeesTable source={"Damex Direct"} />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: (
        <>
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Exchange Treasuries Accounts
          </Divider>
          <ExchangeTable source={"Damex"} />
          <br />
          <Divider
            orientation="left"
            style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
          >
            Fee's Accounts
          </Divider>
          <FeesTable source={"Damex"} />
        </>
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabItems}></Tabs>;
};
export default Fiat;

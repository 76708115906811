import {
  ALL_USERS,
  COMMISSIONERS_INTRODUCERS,
  USERS_REVIEWERS_AML,
} from "../actions/userActions";
const initialState = {
  usersResp: {},
  commissionersOrIntroducers: {},
  usersReviewers: {},
};
export function users(state = initialState, action) {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        usersResp: action.payload,
      };
    case COMMISSIONERS_INTRODUCERS:
      return {
        ...state,
        commissionersOrIntroducers: action.payload,
      };
    case USERS_REVIEWERS_AML:
      return {
        ...state,
        usersReviewers: action.payload,
      };
    default:
      return state;
  }
}

export default users;

export const ALL_TRANSACTION_TYPES = [
  { key: "ALL", value: "All" },
  { key: "CARD_DELIVERY_CHARGES", value: "Card Delivery Charges" },
  { key: "CASH_BACK", value: "Cash Back" },
  { key: "DAMEX_PLAN_ACTIVATION", value: "Damex Plan Activation" },
  { key: "DAMEX_PLAN_EXPIRATION", value: "Damex Plan Expiration" },
  { key: "DEBIT_CARD_PAYMENT", value: "Debit Card Payment" },
  { key: "DEBIT_CARD_REFUND", value: "Debit Card Refund" },
  { key: "DEBIT_CARD_REVERSAL", value: "Debit Card Reversal" },
  { key: "DEPOSIT", value: "Deposit" },
  { key: "EXCHANGE_IN", value: "Exchange in" },
  { key: "EXCHANGE_OUT", value: "Exchange out" },
  { key: "IN_YIELDING_ACTIVATION", value: "In Yielding Activation" },
  {
    key: "IN_YIELDING_EARLY_REDEMPTION",
    value: "In Yielding Early Redemption",
  },
  { key: "IN_YIELDING_EXPIRATION", value: "In Yielding Expiration" },
  { key: "IN_YIELDING_REWARDS", value: "In Yielding Rewards" },
  { key: "REBALANCE_IN", value: "Rebalance in" },
  { key: "REBALANCE_OUT", value: "Rebalance out" },
  { key: "REFERRAL_BONUS", value: "Referral Bonus" },
  { key: "TRANSFER_IN_VERASITY", value: "Transfer in Verasity" },
  { key: "TRANSFER_IN", value: "Transfer in" },
  { key: "TRANSFER_OUT_VERASITY", value: "Transfer out Verasity" },
  { key: "TRANSFER_OUT", value: "Transfer out" },
  { key: "WITHDRAWAL", value: "Withdrawal" },
];

export const getTransactionTypes = (types) => {
  if (!types || types.length === 0) return ALL_TRANSACTION_TYPES;

  return [
    { key: "ALL", value: "All" },
    ...ALL_TRANSACTION_TYPES.filter(
      (type) => types.includes(type.key) && type.key !== "ALL"
    ),
  ];
};

export const PENDING_SETTLEMENT_TYPES = getTransactionTypes([
  "WITHDRAWAL",
  "EXCHANGE_IN",
]);

import React from "react";
import { Table } from "antd";
import { Card, Typography, Select, Row, Col, Button, Switch } from "antd";
import "./freezeWithdrawals.less";
import { asset } from "../../common/assets";
const { Text } = Typography;
const { Option } = Select;
const status = [
  {
    key: "ACTIVE",
    value: "Active",
  },
  {
    key: "INACTIVE",
    value: "Inactive",
  },
  { key: "ALL", value: "All" },
];
const FreezeTable = (props) => {
  const dataSource = [];
  dataSource.push(
    {
      currency: "ETH",
      network: "ETH",
      status: "ACTIVE",
      key: "ETH",
    },
    {
      currency: "BTC",
      network: "BTC",
      status: "INACTIVE",
      key: "BTC",
    },
    {
      currency: "USDC",
      network: "ERC20",
      status: "ACTIVE",
      key: "USDC",
    }
  );

  const currencies = asset.assets.filter((element) => element.type === "FIAT");
  currencies.push({ value: "ALL", name: "All" });

  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Network
        </Text>
      ),
      dataIndex: "network",
      key: "network",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Withdrawals
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          <Switch checked={status === "ACTIVE" ? true : false} />
        </>
      ),
    },
  ];
  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Currency"
          >
            {currencies.map((item, i) => {
              return (
                <Option key={i} value={item.value}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Network"
          >
            <Option key="partner" value="ERC20">
              ERC20
            </Option>
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Status"
          >
            {status.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          xl={{ span: 3, offset: 0 }}
          xxl={{ span: 3, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Filter
          </Button>
        </Col>
      </Row>

      <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        <Table
          rowClassName="row-table-deposit"
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 20 }}
        />
      </div>
    </Card>
  );
};

export default FreezeTable;
